export const ImageGeneratorSidebarKey = 'image_generator';

export const ImageGeneratorPath = '/image_generation';

export const ImageGeneratorTitle = 'Image Generator';

export const ImageGeneratorId = '9c5b646e-2b20-463f-a26d-18e422f5275e';

export const MIN_SYMBOLS_INSTRUCTION_IMPROVE = 8;

export const MAX_IMAGE_GENERATIONS_DAILY_LIMIT = 10;

export const MAX_SYMBOLS_INSTRUCTION_FIELD = 3800;
