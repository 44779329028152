import React, { FC, useState } from 'react';

import clsx from 'clsx';

import styles from './styles.module.scss';

type Props = {
  src: string;
  preview: string;
};

const Image: FC<Props> = ({ src, preview }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div className={styles.image__wrapper}>
      <img
        className={styles.image__main}
        src={src}
        onLoad={(): void => setIsLoaded(true)}
        alt="justdone image"
      />

      <img
        className={clsx(styles.image__preview, {
          [styles.is_hidden]: isLoaded,
        })}
        src={preview}
        alt="justdone preview image"
      />
    </div>
  );
};

export default Image;
