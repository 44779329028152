/* eslint-disable import/no-named-as-default-member */
import { isSupported } from '@firebase/remote-config';
import firebaseApp from 'firebase/compat/app';

import 'firebase/compat/analytics';
import 'firebase/compat/performance';
import 'firebase/compat/remote-config';
import errorLogger from '~/services/ErrorLogger';

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDYwT5DCvChlMTSh3Bsf3lOqoxy-zRnPL8',
  authDomain: 'justdone-7582c.firebaseapp.com',
  projectId: 'justdone-7582c',
  storageBucket: 'justdone-7582c.appspot.com',
  messagingSenderId: '242863764713',
  appId: '1:242863764713:web:903abdf8c16d9bb8772c78',
  measurementId: 'G-9YYHXCVQSG',
};

export const DEFAULT_AB_TEST_CONFIG: Record<string, string> = {};

class Firebase {
  APP = {};

  REMOTE_CONFIG!: firebaseApp.remoteConfig.RemoteConfig;

  init(): Record<string, any> {
    this.APP = firebaseApp.apps.length
      ? firebaseApp.app()
      : firebaseApp.initializeApp({ ...FIREBASE_CONFIG });

    if (process.env.NODE_ENV !== 'development') {
      firebaseApp.analytics();
      firebaseApp.performance();
    }

    return this.APP;
  }

  async getRemoteConfig(defaultConfig: {
    [key: string]: string | number | boolean;
  }): Promise<Record<string, any> | null> {
    const isRemoveConfigSupported = await isSupported(); // https://github.com/firebase/firebase-js-sdk/issues/5502

    if (!isRemoveConfigSupported) {
      return null;
    }

    this.REMOTE_CONFIG = firebaseApp.remoteConfig();
    this.REMOTE_CONFIG.settings.minimumFetchIntervalMillis = 320;
    this.REMOTE_CONFIG.defaultConfig = defaultConfig;

    try {
      await this.REMOTE_CONFIG.fetchAndActivate();
      return this.REMOTE_CONFIG.getAll();
    } catch (error) {
      console.error('Failed to fetch and activate remote config', error);
      return null;
    }
  }

  async getABTestConfig(): Promise<Record<string, any>> {
    try {
      const configResponse = await this.getRemoteConfig(DEFAULT_AB_TEST_CONFIG);

      return configResponse
        ? Object.fromEntries(
            Object.entries(configResponse)
              .filter(([key]) => key in DEFAULT_AB_TEST_CONFIG)
              .map(([key, value]) => [key, value.asString()]),
          )
        : DEFAULT_AB_TEST_CONFIG;
    } catch (e: any) {
      errorLogger.captureException(e, 'AB test config error');
      return DEFAULT_AB_TEST_CONFIG;
    }
  }

  async getNewABTests(
    currentAbTests: Record<string, string>,
  ): Promise<Record<string, string>> {
    const newAbTests: Record<string, string> = {};
    const ABTestConfig: Record<string, any> = await this.getABTestConfig();
    Object.entries(ABTestConfig).map(([key, value]) => {
      if (!(key in currentAbTests)) {
        newAbTests[key] = value;
      }
    });
    return newAbTests;
  }
}

export const firebase = new Firebase();
