import React, { FC, useState } from 'react';

import { styled, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { useSnackBarContext } from '~/features/snackbar/SnackbarContext';
import ButtonsBlock from '~/modules/cancellation/components/ButtonsBlock';
import analytics from '~/services/analytics';
import { colors } from '~/theme/colors';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const StyledFormControl = styled(FormControl)(() => ({
  width: '100%',
}));

const StyledRadio = styled(FormControlLabel)(({ checked }) => ({
  padding: '20px 28px',
  margin: 0,
  borderRadius: '24px',
  boxShadow: `0 0 0 1px ${colors.neutral_40}`,
  background: colors.white,
  transition: 'background 300ms, box-shadow 300ms',

  '&': checked && {
    background: '#EEF0FF',
    boxShadow: `0 0 0 2px ${colors.primary_40}`,
  },

  '&:not(:last-child)': {
    marginBottom: '8px',
  },

  '& .MuiRadio-root': {
    width: '24px',
    height: '24px',
    padding: '0',
    marginRight: '16px',
  },

  '& .MuiFormControlLabel-label': {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

export type PeriodType = {
  value: number;
  unit: 'day' | 'week' | 'month';
  text: string;
};

const periods: PeriodType[] = [
  {
    value: 2,
    unit: 'week',
    text: '2 weeks',
  },
  {
    value: 4,
    unit: 'week',
    text: '4 weeks',
  },
  {
    value: 6,
    unit: 'week',
    text: '6 weeks',
  },
];

const getWarnings = (): { title: string; loses: JSX.Element[] } => {
  return {
    title: 'Okay, how long you want to pause?',
    loses: [
      <>
        Your account will be put on hold <b>immediately</b> and you will not be
        charged for the next payment period.
      </>,
      <>
        If you want to reactivate your account before the end of the hold
        period, you will have the option to do so.
      </>,
      <>
        After the hold period ends, your account will be automatically
        reactivated.
      </>,
    ],
  };
};

type Props = {
  onSubmit: (period: PeriodType) => void;
  onRefuse: () => void;
};

const PausePeriod: FC<Props> = ({ onRefuse, onSubmit }) => {
  const [period, setPeriod] = useState<PeriodType>({
    value: 0,
    unit: 'week',
    text: '',
  });
  const { showSnackbar } = useSnackBarContext();

  const { title, loses } = getWarnings();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPeriod(
      periods.find((p) => p.value === Number(event.target.value)) ?? periods[0],
    );
    analytics.trackEvent('subscription - ac.select pause options');
  };

  const handleSubmit = (): void => {
    if (!period.value) {
      showSnackbar('Please select an option', 'error');
      return;
    }

    analytics.trackEvent('subscription - ac.want pause 2', {
      option: period.unit === 'week' ? period.value / 4 : period.value,
    });
    onSubmit(period);
  };

  return (
    <div>
      <div className={styles.content}>
        <Typography
          variant={Typographies.HEADLINE_SMALL}
          component="h4"
          className={styles.content__title}
        >
          {title}
        </Typography>

        <div className={styles.content__flex}>
          <div className={styles.content__form}>
            <StyledFormControl error={!period.value}>
              <RadioGroup
                name="reason"
                value={period.value}
                onChange={handleChange}
                defaultValue={periods[0]}
              >
                {periods.map((p) => (
                  <StyledRadio
                    key={p.value}
                    value={p.value}
                    control={<Radio />}
                    checked={period.value === p.value}
                    label={p.text}
                  />
                ))}
              </RadioGroup>
            </StyledFormControl>
          </div>

          <ul className={styles.content__list}>
            {loses.map((text, idx) => (
              <li className={styles.content__item} key={idx}>
                <Typography variant={Typographies.BODY_LARGE} component="p">
                  {text}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <ButtonsBlock
        buttonSubmitText="Continue"
        buttonRefuseText="Cancel"
        onButtonSubmit={handleSubmit}
        onButtonRefuse={onRefuse}
        isDisabledSubmitButton={!period}
      />
    </div>
  );
};

export default PausePeriod;
