import {
  EventStreamContentType,
  fetchEventSource,
} from '@microsoft/fetch-event-source';
import { v4 as uuidv4 } from 'uuid';

import { FieldsError, ForbiddenError, StreamVendorError } from '~/Api/Error';
import { MetaDataResponseType, SORT_ORDER } from '~/Api/types';
import { Badges } from '~/components/atoms/Badge/types';
import { decrypt } from '~/helpers/simpleEncryption';
import { OffboardingSupportFeedback } from '~/modules/cancellation/features/SubReasonPrevention/decorators/withFeedbackLogic';
import { UsageStatisticsType } from '~/modules/cancellation/types';
import { MessageType } from '~/modules/chat/components/Chat/elements/Message/constants';
import {
  CHAT_TYPE,
  ChatItemType,
  DTOMessageItemType,
  MESSAGE_CONTEXT,
  MessageItemType,
  PERSONALITY,
  RecommendedChatPromoToolType,
} from '~/modules/chat/types';
import {
  ImageGenerationExecutionItemType,
  FormDataType as ImageGenerationFormDataType,
} from '~/modules/image-generator/types';
import { ONBOARDING_SAVE_KEYS } from '~/modules/onboarding-new/constants';
import { REACTION } from '~/modules/reactions/types';
import { addTrimValidationToScheme } from '~/modules/tools/helpers/validation';
import {
  AiDetectorType,
  AiEditType,
  AiHumanizerType,
  ArticleExecutionType,
  CardColor,
  ExtendedToolType,
  GroupToolsType,
  OutlineItemType,
  PlagiarismCheckerType,
  TOOL_PERMISSION,
  ToolCategoryType,
  ToolExecutionType,
  ToolType,
  UiFieldType,
} from '~/modules/tools/types';
import {
  ArticleOutlineType,
  ArticleType,
} from '~/modules/tools/views/AdvancedArticleGenerator/types';
import { OneTimeMessageType } from '~/modules/tools/views/ToolsGallery/elements/NotFound/variants/InteractiveNotFound';
import {
  AiDetectorExecutionType,
  ExtendAiDetectorExecutionType,
} from '~/modules/tools/views/variant2/AiDetector/useAiDetectorExecutions';
import { HUMANIZER_MODE } from '~/modules/tools/views/variant2/AiHumanizer/constants';
import {
  AiHumanizerExecutionExtendedType,
  AiHumanizerExecutionType,
} from '~/modules/tools/views/variant2/AiHumanizer/useAiHumanizerExecutions';
import {
  ExtendPlagiarismExecutionType,
  PlagiarismExecutionType,
} from '~/modules/tools/views/variant2/PlagiarismChecker/usePlagiarismCheckerExecutions';
import { CustomAbortController } from '~/services/AbortController';
import analytics from '~/services/analytics';
import errorLogger from '~/services/ErrorLogger';
import AbstractStorage from '~/services/storage/AbstractStorage';
import localStorage from '~/services/storage/localStorage';
import { MetaType } from '~/types/common';
import { ProductType, REGULARITY } from '~/types/product';
import { ProfessionAreaType, ProfileType } from '~/types/profile';
import { SUBSCRIPTION_STATUS, SubscriptionType } from '~/types/subscription';
import { capitalizeCaseToSnakeCase } from '~/utils/text';

import {
  Api,
  ModelsAIHumanizerResponse,
  ModelsArticleOutlineItems,
  ModelsChat,
  ModelsChatEvent,
  ModelsChatMessage,
  ModelsImageGenerationPresenter,
  ModelsPlagiarismCheckerListExecutionsItem,
  ModelsProfessionalArea,
  ModelsToolCategory,
  ModelsToolExecutionResponse,
  ModelsToolListItem,
  ModelsUser,
  ModelsZeroGPTAIDetect,
  RequestParams,
} from './generated-api/api';

export const ABORT_CHAT_KEY = 'manual_abort_chat_stream';
export const ABORT_TOOL_EXECUTION_KEY = 'manual_abort_tool_execution_stream';

export const ABORT_TOOL_AI_EDIT_KEY = 'manual_abort_tool_ai_edit_stream';

const PAYMENT_SANDBOX_KEY = 'jd-sandbox';

export const BASE_API_URL = process.env.REST_API_BASE_URL || '/';

const baseApiClient = new Api({
  baseUrl: BASE_API_URL,
});

class FatalError extends Error {}

export class PlatformApi {
  static ACCESS_TOKEN_STORAGE_KEY = 'access_token';
  static REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';

  isInitialized = false;
  accessToken: string | null = null;
  refreshToken: string | null = null;

  storage: AbstractStorage;
  private notAuthorizedCallback?: () => void;
  private updateTokensCallbacks: Array<
    (tokens: { accessToken: string; refreshToken: string }) => void
  > = [];

  constructor({
    storage,
    notAuthorizedCallback,
  }: {
    storage: AbstractStorage;
    notAuthorizedCallback?: () => void;
  }) {
    this.storage = storage;
    this.notAuthorizedCallback = notAuthorizedCallback;
  }

  private async init(): Promise<void> {
    const [accessToken, refreshToken] = await Promise.all([
      this.storage.getByKey(PlatformApi.ACCESS_TOKEN_STORAGE_KEY),
      this.storage.getByKey(PlatformApi.REFRESH_TOKEN_STORAGE_KEY),
    ]);
    if (accessToken && refreshToken) {
      this.setAccessKeys(accessToken, refreshToken);
    }
    this.isInitialized = true;
  }

  subscribeUpdateTokens(
    callback: (tokens: { accessToken: string; refreshToken: string }) => void,
  ): () => void {
    this.updateTokensCallbacks.push(callback);

    return () => {
      this.updateTokensCallbacks = this.updateTokensCallbacks.filter(
        (cb) => cb !== callback,
      );
    };
  }

  static async handleError(e: any, methodName: string): Promise<Error> {
    try {
      if (e.message === 'Invalid response') {
        errorLogger.captureException(e, `Error in ${methodName}`);
      }

      if (e.status === 403) {
        throw new ForbiddenError(e);
      }

      if (e.status === 404) {
        throw Error('Not found');
      }

      if (e.status >= 500) {
        errorLogger.captureException(e, `Server Error in ${methodName}`);
        throw Error('Server error');
      }

      if (e?.error?.fields) {
        throw new FieldsError(e.error);
      }

      if (e?.error?.error) {
        throw new Error(e.error.error);
      }

      if (e instanceof Response) {
        const parsedData = await e.json();

        if (parsedData.fields) {
          throw new FieldsError(parsedData);
        }

        if (parsedData.error) {
          throw new Error(parsedData.error);
        }
      }

      throw e;
    } catch (e: any) {
      if (e instanceof FieldsError) {
        throw e;
      }

      if (e instanceof StreamVendorError) {
        throw e;
      }

      return new Error(e.message || `Error in ${methodName}`);
    }
  }

  private getAuthHeaders(): Record<string, string> {
    if (this.accessToken === null) {
      this.clearAccessKeys();
      throw Error('Not Authorized');
    }
    return {
      'Access-Token': this.accessToken,
    };
  }

  private setAccessKeys(access_token: string, refresh_token: string): string {
    this.accessToken = access_token;
    this.refreshToken = refresh_token;
    this.storage.setByKey(PlatformApi.ACCESS_TOKEN_STORAGE_KEY, access_token);
    this.storage.setByKey(PlatformApi.REFRESH_TOKEN_STORAGE_KEY, refresh_token);
    this.updateTokensCallbacks.forEach((cb) => {
      cb({ accessToken: access_token, refreshToken: refresh_token });
    });
    return access_token;
  }

  private clearAccessKeys(): void {
    this.accessToken = null;
    this.refreshToken = null;
    this.storage.removeByKey(PlatformApi.ACCESS_TOKEN_STORAGE_KEY);
    this.storage.removeByKey(PlatformApi.REFRESH_TOKEN_STORAGE_KEY);
  }

  private async makeAuthorizedRequest<T>(
    requestFunction: (params: RequestParams) => Promise<T>,
    methodName: string,
  ): Promise<T> {
    if (!this.isInitialized) {
      await this.init();
    }
    try {
      try {
        return await requestFunction({ headers: this.getAuthHeaders() });
      } catch (e: any) {
        if (e?.status === 401 || e?.message === 'Not Authorized') {
          try {
            await this.updateTokens();
          } catch (e: any) {
            if (e.message === 'Not Authorized') {
              await this.clearAccessKeys();
              this.notAuthorizedCallback && this.notAuthorizedCallback();
            }
          }
          return await requestFunction({ headers: this.getAuthHeaders() });
        }
        throw e;
      }
    } catch (e) {
      throw await PlatformApi.handleError(e, methodName);
    }
  }

  private getPaymentSandboxKey(user_id: string): string | null {
    let sandbox: string | null = null;
    try {
      const publicSandboxKey = localStorage.getByKey(PAYMENT_SANDBOX_KEY);
      if (publicSandboxKey) {
        sandbox = decrypt(user_id, publicSandboxKey);
      }
    } catch (error) {
      console.error(error);
    }
    return sandbox;
  }

  async updateTokens(): Promise<void> {
    try {
      if (this.refreshToken && this.accessToken) {
        const { data } = await baseApiClient.api.v1AuthRefreshTokenCreate({
          refresh_token: this.refreshToken,
          access_token: this.accessToken,
        });

        const { access_token, refresh_token } = data.data;

        if (access_token !== undefined && refresh_token !== undefined) {
          this.setAccessKeys(access_token, refresh_token);
        }
      }
    } catch (e) {
      console.error(e);
      this.clearAccessKeys();
      throw Error('Not Authorized');
    }
  }

  async signIn(email: string, password: string): Promise<void> {
    try {
      const { data } = await baseApiClient.api.v1AuthLoginCreate({
        email: email.trim(),
        password,
      });
      const { access_token, refresh_token } = data.data;
      if (access_token && refresh_token) {
        this.setAccessKeys(access_token, refresh_token);
      }
    } catch (error) {
      throw await PlatformApi.handleError(error, 'signIn');
    }
  }

  async chromeSignIn(
    email: string,
    password: string,
  ): Promise<{ accessToken: string; refreshToken: string }> {
    try {
      const { data } = await baseApiClient.api.v1AuthLoginCreate({
        email: email.trim(),
        password,
      });
      const { access_token, refresh_token } = data.data;
      if (access_token && refresh_token) {
        return {
          accessToken: access_token,
          refreshToken: refresh_token,
        };
      }
      throw new Error('Invalid response');
    } catch (error) {
      throw await PlatformApi.handleError(error, 'signIn');
    }
  }

  async signUp(params: {
    email: string;
    password: string;
    name: string;
    extra_fields?: Record<string, any>;
  }): Promise<void> {
    try {
      // @ts-ignore
      const { data } = await baseApiClient.api.v1AuthSignUpCreate({
        ...params,
        email: params.email.trim(),
      });
      const { access_token, refresh_token } = data.data;
      if (access_token && refresh_token) {
        this.setAccessKeys(access_token, refresh_token);
      }
    } catch (error) {
      throw await PlatformApi.handleError(error, 'signUp');
    }
  }

  async forgotPassword(email: string): Promise<void> {
    try {
      await baseApiClient.api.v1PasswordForgotCreate({
        email: email.trim(),
      });
    } catch (error) {
      throw await PlatformApi.handleError(error, 'forgotPassword');
    }
  }

  async resetPassword(token: string, password: string): Promise<void> {
    try {
      await baseApiClient.api.v1PasswordResetCreate({
        token,
        password,
      });
    } catch (error) {
      throw await PlatformApi.handleError(error, 'resetPassword');
    }
  }

  async signOut(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      try {
        await baseApiClient.api.v1AuthLogoutCreate(params);
        this.clearAccessKeys();
      } catch (e) {
        throw await PlatformApi.handleError(e, 'signOut');
      }
    }, 'signOut');
  }

  static mapProfileResponse = (profile: ModelsUser): ProfileType => {
    return {
      id: profile.id || '',
      email: profile.email || '',
      name: profile.name || '',
      avatar: profile.avatar_url || '',
      words_amount_left: profile.words_amount_left || 0,
      response_count_total: profile.response_count_total || 0,
      // @ts-ignore
      user_product: profile.user_product || {},
      // @ts-ignore
      user_subscription: PlatformApi.mapSubscriptionResponse(
        profile.user_subscription || {},
      ),
      // @ts-ignore
      user_onboarding: profile.user_onboarding || {},
      is_onboarded: profile.is_onboarded ?? true,
      is_chrome_extension_installed:
        profile.is_chrome_extension_installed ?? false,
      created_at: profile.created_at || '',
      updated_at: profile.updated_at || '',
      backend_ab_tests: profile.ab_tests || {},
      user_features: {
        image_generation_tool_enabled:
          // @ts-ignore
          profile?.user_features?.image_generation_enabled || false,
      },
      extra_fields: {
        // @ts-ignore
        notifications: profile?.extra_fields?.notifications || {},
        // @ts-ignore
        ab_tests: profile?.extra_fields?.ab_tests || {},
        // @ts-ignore
        highlights: profile?.extra_fields?.highlights || {},
        // @ts-ignore
        test_form: profile?.extra_fields?.test_form || {},
        // @ts-ignore
        sign_up_at: profile?.extra_fields?.sign_up_at || null,
        // @ts-ignore
        cancel_sub_reasons: profile?.extra_fields?.cancel_sub_reasons || {},
        // @ts-ignore
        rewards: profile?.extra_fields?.rewards || {},
        // @ts-ignore
        feedbackV2: profile?.extra_fields?.feedbackV2 || {},
        pwa_modal: {
          last_showed_at:
            // @ts-ignore
            profile?.extra_fields?.pwa_modal?.last_showed_at || null,
          is_showed_first_generation:
            // @ts-ignore
            profile?.extra_fields?.pwa_modal?.is_showed_first_generation ||
            false,
          is_showed_fifth_session:
            // @ts-ignore
            profile?.extra_fields?.pwa_modal?.is_showed_fifth_session || false,
        },
      },
    };
  };

  static mapProfessionalAreas = (
    professionalArea: ModelsProfessionalArea,
  ): ProfessionAreaType => {
    return {
      id: professionalArea.id || '',
      icon: professionalArea.icon || '',
      title: professionalArea.title || '',
      description: professionalArea.description || '',
    };
  };

  static mapProductResponse = (product): ProductType => {
    return {
      created_at: product.created_at || '',
      is_unlimited: product.is_unlimited || false,
      currency: product.currency || 'USD',
      has_trial_period: product.has_trial_period || false,
      id: product.id || '',
      is_solid: product.is_solid || false,
      name: product.name || '',
      price: product.price || 0,
      subscription_type: product.subscription_type || '',
      trial_amount: product.trial_amount || 0,
      trial_currency: product.trial_currency || 'USD',
      trial_period: product.trial_period || 0,
      trial_words_amount: product.trial_words_amount || 0,
      updated_at: product.updated_at || '',
      words_amount: product.words_amount || 0,
      regularity: product.regularity || REGULARITY.MONTH,
      new_price_ui: product.new_price_ui || 0,
      old_price_ui: product.old_price_ui || 0,
      discount_percentage_ui: product.discount_percentage_ui || 0,
      regularity_ui: product.regularity_ui || REGULARITY.MONTH,
      description_ui: product.description_ui || '',
      details_ui: product.details_ui || '',
      is_popular_ui: product.is_popular_ui || false,
    };
  };

  static mapSubscriptionResponse = (subscription): SubscriptionType => {
    return {
      id: subscription.id ?? '',
      is_unlimited: subscription.is_unlimited || false,
      billing_period: subscription.billing_period || REGULARITY.MONTH,
      product_id: subscription.product_id || '',
      subscription_price: subscription.subscription_price || 0,
      subscription_name: subscription.subscription_name || '',
      status: subscription.status || SUBSCRIPTION_STATUS.ACTIVE,
      started_at: subscription.started_at || '',
      expired_at: subscription.expired_at || null,
      next_charge_at: subscription.next_charge_at || null,
      cancelled_at: subscription.cancelled_at || null,
      is_trial: subscription.is_trial || false,
      cancel_code: subscription.cancel_code || null,
      cancel_message: subscription.cancel_message || null,
      payment_type: subscription.payment_type || 'card',
      pause_from: subscription.pause_from || null,
      pause_to: subscription.pause_to || null,
      created_at: subscription.created_at || '',
      updated_at: subscription.updated_at || '',
      words_amount_left: subscription.words_amount_left || 0,
      words_amount_total: subscription.words_amount_total || 0,
      words_generated_total: subscription.words_generated_total || 0,
      saved_time_minutes: subscription.saved_time_minutes || 0,
      response_count_total: subscription.response_count_total || 0,
    };
  };

  static mapToolExecutionResponse = (
    item: ModelsToolExecutionResponse & { title?: string },
  ): ToolExecutionType & { title: string } => {
    return {
      id: item.id || '',
      title: item.title || '',
      text: item.ai_response || '',
      reaction: item.user_reaction ?? REACTION.NONE,
      symbols: item.chars_count || 0,
      words: item.words_count || 0,
      created_at: item.created_at || '',
      updated_at: item.updated_at || '',
      ai_edit_items: item.ai_edit_items?.length
        ? item.ai_edit_items.map(({ id, ai_edit_result, user_reaction }) => ({
            id: id || '',
            text: ai_edit_result || '',
            reaction: user_reaction || 0,
          }))
        : [],
      children:
        item.children && item.children.length
          ? item.children.map((item) =>
              PlatformApi.mapToolExecutionResponse(item),
            )
          : [],
      user_inputs: item.user_inputs || {},
    };
  };

  static mapPlagiarismExecutionResponse = (
    item: ModelsPlagiarismCheckerListExecutionsItem,
  ): PlagiarismExecutionType => {
    return {
      id: item.export_id || '',
      aggregated_score: item.aggregated_score || 0,
      identical_words: item.identical_words || 0,
      minor_changed_words: item.minor_changed_words || 0,
      related_meaning_words: item.related_meaning_words || 0,
      sources_count: item.sources_count || 0,
      document_link: item.document_link || '',
      reaction: item.reaction || 0,
      request_message: item.request_message || '',
      is_contain_quotes: item.is_contain_quotes || false,
    };
  };

  static mapAiExecutionResponse = (
    item: ModelsAIHumanizerResponse,
  ): AiHumanizerExecutionType => {
    return {
      id: item.execution_id || '',
      purpose: item.purpose || '',
      readability: item.readability || '',
      mode: (item.mode || '') as HUMANIZER_MODE,
      reaction: item.user_reaction ?? REACTION.NONE,
      request_message: item.request_message || '',
      response_message: item.response_message || '',
      created_at: item.created_at || '',
      updated_at: item.updated_at || '',
      ai_edit_items: item.ai_edits?.length
        ? item.ai_edits.map(({ id, ai_edit_result, user_reaction }) => ({
            id: id || '',
            text: ai_edit_result || '',
            reaction: user_reaction || 0,
          }))
        : [],
    };
  };

  static mapImageGenerationExecutionResponse = (
    item: ModelsImageGenerationPresenter,
  ): ImageGenerationExecutionItemType => {
    return {
      id: item.id || '',
      instruction: item.instruction || '',
      size: (item.image_size || '1024x1024') as
        | '1024x1024'
        | '1024x1792'
        | '1792x1024',
      hd_quality: item.hd_quality || false,
      style: (item.style || 'vivid') as 'vivid' | 'natural',
      preview_image: item.preview_base64_image || '',
      image_png_src: item.image_png_src || '',
      image_jpeg_src: item.image_jpeg_src || '',
      reaction: (item.reaction || 0) as -1 | 0 | 1,
      created_at: item.created_at || '',
      updated_at: item.updated_at || '',
    };
  };

  static mapAiDetectorExecutionResponse = (
    item: ModelsZeroGPTAIDetect,
  ): AiDetectorExecutionType => {
    return {
      id: item.id || '',
      detected_sentences: item.detected_sentences || [],
      request_message: item.input_text || '',
      // @ts-ignore
      ai_detection_percent: item.is_human || 0,
      reaction: item.user_reaction || REACTION.NONE,
      // @ts-ignore
      created_at: item.created_at || '',
      // @ts-ignore
      updated_at: item.updated_at || '',
    };
  };

  static mapArticleExecutionResponse = (item): ArticleExecutionType => {
    return {
      id: item.id || '',
      title: item.title || '',
      text: item.ai_response || '',
      reaction: item.user_reaction ?? REACTION.NONE,
      symbols: item.chars_count || 0,
      words: item.words_count || 0,
      created_at: item.created_at || '',
      updated_at: item.updated_at || '',
    };
  };

  static mapOutlineItemsResponse = (
    responseItem: ModelsArticleOutlineItems,
  ): OutlineItemType => {
    return {
      id: uuidv4(),
      content: responseItem.content || '',
      items: responseItem.items
        ? responseItem.items.map((item) =>
            PlatformApi.mapOutlineItemsResponse(item),
          )
        : [],
    };
  };

  static mapToolCategoriesResponse(item: ModelsToolCategory): ToolCategoryType {
    return {
      id: item.id || '',
      name: item.name || '',
      description: item.description || '',
    };
  }

  static filterMessagesResponse = (
    messages: ModelsChatMessage[],
  ): ModelsChatMessage[] => {
    return messages.filter(
      ({ message_context }) =>
        (message_context as unknown as MESSAGE_CONTEXT) !==
        MESSAGE_CONTEXT.SYSTEM,
    );
  };

  static mapMessageResponse = (
    message: ModelsChatMessage & {
      recommended_tool?: RecommendedChatPromoToolType;
    },
  ): MessageItemType => {
    return {
      id: message.id || '',
      type: MessageType.regular,
      body: {
        response_message: message.ui_response || message.ai_response || '',
        request_message: message.ui_prompt || message.user_prompt || '',
        reaction: (message.user_reaction as REACTION) ?? REACTION.NONE,
        personality: (message.personality ||
          PERSONALITY.GENERAL) as PERSONALITY,
        ...(message.recommended_tool
          ? { recommended_tool: message.recommended_tool }
          : {}),
      },
      created_at: message.created_at || '',
      updated_at: message.updated_at || '',
    };
  };

  static mapTemplateResponse = (
    tool: ModelsToolListItem & {
      last_inputs: Record<string, string>;
      is_limited?: boolean;
    },
  ): ExtendedToolType => {
    return {
      id: tool.id || '',
      category_id: tool.category_id || '',
      slug: tool.slug || '',
      category_name: tool.category_title || '',
      image_src: tool.image_src || '',
      symbol: tool.emoji || '',
      title: tool.name || '',
      description: tool.description || '',
      path: tool.path || '',
      tags: (tool.tags || []) as Badges[],
      ui_schema: (tool.ui_schema || {}) as Record<string, UiFieldType>,
      validation_schema:
        tool.json_schema && tool.ui_schema
          ? addTrimValidationToScheme(
              tool.json_schema,
              tool.ui_schema as Record<string, UiFieldType>,
            )
          : {},

      color: CardColor.DEFAULT,
      last_inputs: tool.last_inputs || {},
      permissions: tool.is_limited ? [] : [TOOL_PERMISSION.READ],
      ai_edit_options: [
        ...(tool.ai_edit_actions?.map(({ action, name }) => ({
          text: name || '',
          value: (action || '') as string,
        })) || []),
        ...(tool.allow_manual_ai_edit
          ? [{ text: 'Manual edit', value: 'manual_edit' }]
          : []),
      ],
    };
  };

  static mapChatResponse = (message: ModelsChat): ChatItemType => {
    return {
      id: message.id || '',
      name: message.name || '',
      created_at: message.created_at || '',
      updated_at: message.updated_at || '',
      chat_type: (message.chat_type || '') as CHAT_TYPE,
      current_personality: (message.current_personality || '') as PERSONALITY,
    };
  };

  async getProfile(): Promise<ProfileType> {
    return this.makeAuthorizedRequest<ProfileType>(async (params) => {
      const { data } = await baseApiClient.api.v1ProfileList(params);
      if (data.data) {
        return PlatformApi.mapProfileResponse(data.data);
      }
      throw Error('Invalid response');
    }, 'getProfile');
  }

  async getProfessionalAreas(): Promise<ProfessionAreaType[]> {
    return this.makeAuthorizedRequest<ProfessionAreaType[]>(async (params) => {
      const { data } = await baseApiClient.api.v1ProfessionalAreasList(params);
      if (data.data) {
        return data.data.map((item: ModelsProfessionalArea) =>
          PlatformApi.mapProfessionalAreas(item),
        );
      }
      throw Error('Invalid response');
    }, 'getProfessionalAreas');
  }

  async getChatList({
    query,
  }: {
    query: {
      page?: number;
      per_page?: number;
      sort_by?: string;
      sort_type?: string;
    };
  }): Promise<{
    data: ChatItemType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ChatItemType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1ChatsList(query, params);

      if (data) {
        const items = data.data;
        const meta = data.meta;
        return {
          data: items
            ? items.map((item) => PlatformApi.mapChatResponse(item))
            : [],
          meta: {
            currentPage: meta?.current_page || query.page,
            pageSize: meta?.page_size || 20,
            totalCount: meta?.total_count || 0,
            totalPages: meta?.total_pages || 1,
          },
        };
      }
      throw Error('Invalid response in getChatList');
    }, 'getChatList');
  }

  async getMessagesByChatId(
    chatId: string,
    {
      query,
    }: {
      query: {
        page?: number;
        per_page?: number;
        sort_by?: string;
        sort_type?: string;
      };
    },
  ): Promise<{
    data: MessageItemType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: MessageItemType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1ChatsMessagesDetail(
        chatId,
        query,
        params,
      );

      if (data) {
        const items = data.data;
        const meta = data.meta;
        return {
          data: items
            ? PlatformApi.filterMessagesResponse(items).map((item) =>
                PlatformApi.mapMessageResponse(item),
              )
            : [],
          meta: {
            currentPage: meta?.current_page || query.page,
            pageSize: meta?.page_size || 20,
            totalCount: meta?.total_count || 0,
            totalPages: meta?.total_pages || 1,
          },
        };
      }
      throw Error('Invalid response');
    }, 'getMessagesByChatId');
  }

  async sendChatPrompt(
    {
      message,
      message_type,
      chat_id,
      personality,
      ui_message,
      ui_response,
      message_context = MESSAGE_CONTEXT.DEFAULT,
    }: DTOMessageItemType,
    callback: {
      onMessageChunkReceive?: (data: {
        message_id: string;
        chunk: string;
      }) => void;
      onChatCreated: (chat: ChatItemType) => void;
      onMessageComplete: (message: MessageItemType) => void;
      onClose?: () => void;
    },
    abortController?: CustomAbortController,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      return new Promise((resolve, reject): void => {
        if (abortController) {
          abortController.instance.signal.onabort = (e): void => {
            // @ts-ignore
            if (e.currentTarget?.reason === ABORT_CHAT_KEY) {
              callback.onClose && callback.onClose();
            }
          };
        }

        let recommended_tool = null;

        fetchEventSource(`${baseApiClient.baseUrl}/api/v1/chats/events`, {
          method: 'POST',
          openWhenHidden: true,
          signal: abortController?.instance.signal,
          headers: {
            // @ts-ignore
            'Access-Token': params?.headers['Access-Token'] || '',
          },
          body: JSON.stringify({
            message,
            message_type,
            chat_id,
            personality,
            message_context,
            ui_prompt: ui_message,
            ui_response,
          }),
          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              return; // everything's good
            } else if (response.status === 401 || response.status === 403) {
              abortController?.instance.abort();
              abortController?.resetInstance();
              reject(new FatalError('Not Authorized'));
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              try {
                const body = await response.json();
                throw new FatalError(body.error);
              } catch (e: any) {
                abortController?.instance.abort();
                abortController?.resetInstance();
                reject(new FatalError(e.message || ''));
              }
            }
          },
          onmessage(event) {
            if (!event.data) {
              return;
            }
            const data = JSON.parse(event.data);

            if (!data.event_type) {
              return;
            }

            switch (data.event_type) {
              case ModelsChatEvent.EventChatCreated:
                callback.onChatCreated(PlatformApi.mapChatResponse(data.data));
                break;
              case ModelsChatEvent.ChatToolPromoReady:
                recommended_tool = data.data;
                break;
              case ModelsChatEvent.EventAIResponseChunk:
                callback.onMessageChunkReceive &&
                  callback.onMessageChunkReceive(data.data);
                break;
              case ModelsChatEvent.EventConversationComplete:
                callback.onMessageComplete(
                  PlatformApi.mapMessageResponse({
                    ...data.data,
                    recommended_tool,
                  }),
                );
                break;
            }
          },
          onclose() {
            callback.onClose && callback.onClose();
            resolve();
          },
        });
      });
    }, 'sendChatPrompt');
  }

  async sendOneTimePromptCompleted(prompt: string): Promise<string> {
    return this.makeAuthorizedRequest<string>(async (params) => {
      const abortController = new AbortController();
      return new Promise((resolve, reject): void => {
        fetchEventSource(`${baseApiClient.baseUrl}/api/v1/prompt/simple`, {
          method: 'POST',
          openWhenHidden: true,
          signal: abortController.signal,
          headers: {
            // @ts-ignore
            'Access-Token': params?.headers['Access-Token'] || '',
          },
          body: JSON.stringify({
            prompt,
          }),
          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              return; // everything's good
            } else if (response.status === 401 || response.status === 403) {
              abortController.abort();
              reject(new FatalError('Not Authorized'));
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              try {
                const body = await response.json();
                throw new FatalError(body.error);
              } catch (e: any) {
                abortController.abort();
                reject(new FatalError(e.message || ''));
              }
            }
          },

          onmessage(event) {
            if (!event.data) {
              return;
            }
            const data = JSON.parse(event.data);

            if (!data.event_type) {
              return;
            }

            if (
              data.event_type === ModelsChatEvent.EventSimplePropmptComplete
            ) {
              resolve(data.data.ai_response);
              return;
            }

            return '';
          },
          onclose() {
            resolve('');
          },
        });
      });
    }, 'sendOneTimePromptCompleted');
  }

  async sendOneTimePromptChunked(
    prompt: string,
    callback: {
      onMessageChunkReceive?: (data: {
        message_id: string;
        chunk: string;
      }) => void;
      onChatCreated?: (chat: ChatItemType) => void;
      onMessageComplete: (message: OneTimeMessageType) => void;
      onClose?: () => void;
    },
    abortController?: CustomAbortController,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      return new Promise((resolve, reject): void => {
        if (abortController) {
          abortController.instance.signal.onabort = (e): void => {
            // @ts-ignore
            if (e.currentTarget?.reason === ABORT_CHAT_KEY) {
              callback.onClose && callback.onClose();
            }
          };
        }

        fetchEventSource(`${baseApiClient.baseUrl}/api/v1/prompt/simple`, {
          method: 'POST',
          openWhenHidden: true,
          signal: abortController?.instance.signal,
          headers: {
            // @ts-ignore
            'Access-Token': params?.headers['Access-Token'] || '',
          },
          body: JSON.stringify({
            prompt,
          }),
          async onopen(response) {
            if (
              response.ok &&
              response.headers.get('content-type') === EventStreamContentType
            ) {
              return; // everything's good
            } else if (response.status === 401 || response.status === 403) {
              abortController?.instance.abort();
              abortController?.resetInstance();
              reject(new FatalError('Not Authorized'));
            } else if (
              response.status >= 400 &&
              response.status < 500 &&
              response.status !== 429
            ) {
              try {
                const body = await response.json();
                throw new FatalError(body.error);
              } catch (e: any) {
                abortController?.instance.abort();
                abortController?.resetInstance();
                reject(new FatalError(e.message || ''));
              }
            }
          },
          onmessage(event) {
            if (!event.data) {
              return;
            }
            const data = JSON.parse(event.data);

            if (!data.event_type) {
              return;
            }

            switch (data.event_type) {
              case ModelsChatEvent.EventSimplePromptChunk:
                callback.onMessageChunkReceive &&
                  callback.onMessageChunkReceive(data.data);
                break;
              case ModelsChatEvent.EventSimplePropmptComplete:
                callback.onMessageComplete({
                  response_message: data.data.ai_response,
                  request_message: prompt,
                });

                break;
            }
          },
          onclose() {
            callback.onClose && callback.onClose();
            resolve();
          },
        });
      });
    }, 'sendOneTimePromptChunked');
  }

  async getPromptImprover(prompt: string): Promise<string> {
    return this.makeAuthorizedRequest<string>(async (params) => {
      try {
        const { data } = await baseApiClient.api.v1PromptImproveCreate(
          { prompt },
          params,
        );

        if (data.data) {
          return data.data.improved_prompt;
        }

        throw Error('Invalid response');
      } catch (e: any) {
        if (e.status === 429) {
          throw Error('Too Many Requests');
        }
      }
    }, 'getPromptImprover');
  }

  async sendTestFormData(dataToSend: {
    input: string;
    source_name: string;
  }): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ProfileTestsBrandVoiceCreate(
        dataToSend,
        params,
      );
    }, 'sendTestFormData');
  }

  async createChat(payload: {
    chat_type?: string;
    current_personality?: PERSONALITY;
    chat_name?: string;
    messages?: OneTimeMessageType[];
  }): Promise<ChatItemType> {
    return this.makeAuthorizedRequest<ChatItemType>(async (params) => {
      const { data } = await baseApiClient.api.v1ChatsCreate(
        {
          // @ts-ignore
          chat_type: payload.chat_type,
          // @ts-ignore
          personality: payload.current_personality,
          name: payload.chat_name,
          messages: payload.messages?.map((item) => ({
            ai_response: item.response_message,
            user_prompt: item.request_message,
          })),
        },
        params,
      );

      if (data.data) {
        return data.data;
      }

      throw Error('Invalid response in createChat');
    }, 'createChat');
  }

  async deleteChat(chatId: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ChatsDelete(chatId, params);
    }, 'deleteChat');
  }

  async updateChat(
    chatId: string,
    payload: {
      chat_type?: string;
      current_personality?: PERSONALITY;
      name?: string;
    },
  ): Promise<ChatItemType> {
    return this.makeAuthorizedRequest<ChatItemType>(async (params) => {
      const { data } = await baseApiClient.api.v1ChatsUpdate(
        chatId,
        // @ts-ignore
        payload,
        params,
      );

      if (data.data) {
        return data.data;
      }
    }, 'updateChat');
  }

  async getGroupedTools(): Promise<GroupToolsType[]> {
    return this.makeAuthorizedRequest<GroupToolsType[]>(async (params) => {
      const { data } = await baseApiClient.api.v1ToolsList(params);

      if (data.data) {
        return data.data.map((group) => ({
          group_name: group.name || '',
          group_id: capitalizeCaseToSnakeCase(group.name.toLowerCase()),
          items: group.items.map((tool) =>
            PlatformApi.mapTemplateResponse(tool),
          ),
        }));
      }
      throw Error('Invalid response in getGroupedTools');
    }, 'getGroupedTools');
  }

  async getToolCategories({
    query,
  }: {
    query: {
      sort_type?: SORT_ORDER;
      sort_by?: string;
      page: number;
      per_page: number;
    };
  }): Promise<{
    data: ToolCategoryType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ToolCategoryType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1ToolCategoriesList(
        query,
        params,
      );

      if (data.data && data.meta) {
        const categories = data.data;
        const meta = data.meta;
        return {
          data: categories.map((item) =>
            PlatformApi.mapToolCategoriesResponse(item),
          ),
          meta: {
            currentPage: meta.current_page ?? 1,
            pageSize: meta.page_size ?? 10,
            totalCount: meta.total_count ?? 0,
            totalPages: meta.total_pages ?? 1,
          },
        };
      }
      throw Error('Invalid response');
    }, 'getToolCategories');
  }

  async getTools({
    query,
  }: {
    query: {
      category_ids?: string;
      tags?: string;
      search_fields?: string;
      search_text?: string;
      sort_type?: SORT_ORDER;
      sort_by?: string;
      page: number;
      per_page: number;
    };
  }): Promise<{
    data: ToolType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ToolType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1ToolsByFiltersList(
        query,
        params,
      );

      if (data.meta) {
        const tools = data.data || [];
        const meta = data.meta;

        return {
          data: tools.map((item) => PlatformApi.mapTemplateResponse(item)),
          meta: {
            currentPage: meta.current_page ?? 1,
            pageSize: meta.page_size ?? 10,
            totalCount: meta.total_count ?? 0,
            totalPages: meta.total_pages ?? 1,
          },
        };
      }
      throw Error('Invalid response in getTools');
    }, 'getTools');
  }

  async getToolById(id: string): Promise<ExtendedToolType> {
    return this.makeAuthorizedRequest<ExtendedToolType>(async (params) => {
      const { data } = await baseApiClient.api.v1ToolsDetail(id, params);
      return PlatformApi.mapTemplateResponse(data.data);
    }, 'getToolById');
  }

  async regenerateOutlineSection(
    topic: string,
    items: OutlineItemType[],
    regenerateIndex: number,
  ): Promise<{
    words: number;
    items: OutlineItemType[];
  }> {
    return this.makeAuthorizedRequest<{
      words: number;
      items: OutlineItemType[];
    }>(async (params) => {
      return new Promise((resolve, reject): void => {
        const controller = new AbortController();
        fetchEventSource(
          `${baseApiClient.baseUrl}/api/v1/tools/advanced_article_generator/regenerate-outline-section`,
          {
            method: 'POST',
            openWhenHidden: true,
            signal: controller.signal,
            headers: {
              // @ts-ignore
              'Access-Token': params?.headers['Access-Token'] || '',
            },
            body: JSON.stringify({
              outline: {
                topic,
                items,
              },
              regenerate_index: regenerateIndex,
            }),
            async onopen(response) {
              if (
                response.ok &&
                response.headers.get('content-type') === EventStreamContentType
              ) {
                return; // everything's good
              } else if (response.status === 401 || response.status === 403) {
                controller.abort();
                reject(new FatalError('Not Authorized'));
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                try {
                  const body = await response.json();
                  throw new FatalError(body.error);
                } catch (e: any) {
                  controller.abort();
                  reject(new FatalError(e.message || ''));
                }
              }
            },
            onmessage(event) {
              if (!event.data) {
                return;
              }
              const data = JSON.parse(event.data);
              if (!data.event_type) {
                return;
              }

              switch (data.event_type) {
                case ModelsChatEvent.EventAGAOutlineReady:
                  resolve({
                    words: data?.data.word_count,
                    items: data?.data.items.map(
                      PlatformApi.mapOutlineItemsResponse,
                    ),
                  });
                  break;
              }
            },
          },
        );
      });
    }, 'regenerateOutlineSection');
  }

  async getOutlineByTopic(topic: string): Promise<ArticleOutlineType> {
    return this.makeAuthorizedRequest<ArticleOutlineType>(async (params) => {
      return new Promise((resolve, reject): void => {
        const controller = new AbortController();
        fetchEventSource(
          `${baseApiClient.baseUrl}/api/v1/tools/advanced_article_generator/generate-outline`,
          {
            method: 'POST',
            openWhenHidden: true,
            signal: controller.signal,
            headers: {
              // @ts-ignore
              'Access-Token': params?.headers['Access-Token'] || '',
            },
            body: JSON.stringify({ topic }),
            async onopen(response) {
              if (
                response.ok &&
                response.headers.get('content-type') === EventStreamContentType
              ) {
                return; // everything's good
              } else if (response.status === 401 || response.status === 403) {
                controller.abort();
                reject(new FatalError('Not Authorized'));
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                try {
                  const body = await response.json();
                  throw new FatalError(body.error);
                } catch (e: any) {
                  controller.abort();
                  reject(new FatalError(e.message || ''));
                }
              }
            },
            onmessage(event) {
              if (!event.data) {
                return;
              }
              const data = JSON.parse(event.data);
              if (!data.event_type) {
                return;
              }

              switch (data.event_type) {
                case ModelsChatEvent.EventAGAOutlineReady:
                  resolve({
                    topic: data?.data.topic,
                    words: data?.data.word_count,
                    items: data?.data.items.map(
                      PlatformApi.mapOutlineItemsResponse,
                    ),
                  });
                  break;
              }
            },
          },
        );
      });
    }, 'getOutlineByTopic');
  }

  async getArticleByOutline(
    topic: string,
    items: OutlineItemType[],
  ): Promise<ArticleExecutionType> {
    return this.makeAuthorizedRequest<ToolExecutionType & { title: string }>(
      async (params) => {
        return new Promise((resolve, reject): void => {
          const controller = new AbortController();
          fetchEventSource(
            `${baseApiClient.baseUrl}/api/v1/tools/advanced_article_generator/generate-article`,
            {
              method: 'POST',
              openWhenHidden: true,
              signal: controller.signal,
              headers: {
                // @ts-ignore
                'Access-Token': params?.headers['Access-Token'] || '',
              },
              body: JSON.stringify({ outline: { topic, items } }),
              async onopen(response) {
                if (
                  response.ok &&
                  response.headers.get('content-type') ===
                    EventStreamContentType
                ) {
                  return; // everything's good
                } else if (response.status === 401 || response.status === 403) {
                  controller.abort();
                  reject(new FatalError('Not Authorized'));
                } else if (
                  response.status >= 400 &&
                  response.status < 500 &&
                  response.status !== 429
                ) {
                  try {
                    const body = await response.json();
                    throw new FatalError(body.error);
                  } catch (e: any) {
                    controller.abort();
                    reject(new FatalError(e.message || ''));
                  }
                }
              },
              onmessage(event) {
                if (!event.data) {
                  return;
                }
                const data = JSON.parse(event.data);
                if (!data.event_type) {
                  return;
                }

                switch (data.event_type) {
                  case ModelsChatEvent.EventToolExecutionComplete:
                    resolve(
                      PlatformApi.mapToolExecutionResponse(
                        data.data.execution_results[0],
                      ),
                    );
                    break;
                }
              },
            },
          );
        });
      },
      'getArticleByOutline',
    );
  }

  async getLastArticleResults(): Promise<ArticleType> {
    return this.makeAuthorizedRequest(async (params) => {
      const { data } =
        await baseApiClient.api.v1ToolsAdvancedArticleGeneratorLastResultsList(
          params,
        );

      if (data.data) {
        return {
          content: data.data.content.execution_results
            ? PlatformApi.mapArticleExecutionResponse(
                data.data.content.execution_results[0],
              )
            : null,
          topic: data.data.topic,
          outline: data.data.outline.items
            ? {
                ...data.data.outline,
                items: data.data.outline.items.map(
                  PlatformApi.mapOutlineItemsResponse,
                ),
              }
            : null,
        };
      }

      if (data.data === null) {
        return data.data;
      }

      throw Error('Invalid response in getLastArticleResults');
    }, 'getLastArticleResults');
  }

  async updateArticleOutline(outline: ArticleOutlineType): Promise<void> {
    return this.makeAuthorizedRequest(async (params) => {
      const response =
        await baseApiClient.api.v1ToolsAdvancedArticleGeneratorUpdateOutlineCreate(
          { outline },
          params,
        );

      if (response.status !== 200) {
        throw Error('Invalid response in updateArticleOutline');
      }
    }, 'updateArticleOutline');
  }

  async getToolBySlug(slug: string): Promise<ExtendedToolType> {
    return this.makeAuthorizedRequest<ExtendedToolType>(async (params) => {
      const { data } = await baseApiClient.api.v1ToolsSlugDetail(slug, params);
      if (data.data) {
        return PlatformApi.mapTemplateResponse(data.data);
      }
      throw Error('Invalid response in getToolBySlug');
    }, 'getToolBySlug');
  }

  async updateToolExecution(
    resultId: string,
    toolId: string,
    dataToUpdate: {
      text: string;
    },
  ): Promise<ToolExecutionType> {
    return this.makeAuthorizedRequest<ToolExecutionType>(async (params) => {
      const { data } = await baseApiClient.api.v1ToolsResultsUpdate(
        resultId,
        toolId,
        { ai_response: dataToUpdate.text },
        params,
      );

      if (data.data) {
        return PlatformApi.mapToolExecutionResponse(data.data);
      }
      throw Error('Invalid response');
    }, 'updateToolExecution');
  }

  async deleteToolExecution(toolId: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      const response = await baseApiClient.api.v1ToolsExecutionsDelete(
        toolId,
        params,
      );

      if (response.status !== 200) {
        throw Error('Invalid response');
      }
    }, 'deleteToolExecution');
  }

  async sendToolFormData(
    messageData: {
      tool_id: string;
      form_data: Record<string, any>;
    },
    callback: {
      onComplete: (messages: ToolExecutionType[]) => void;
      onCreate?: (messages: ToolExecutionType[]) => void;
      onChunkReceive?: (data: { text: string; id: string }) => void;
      onClose?: () => void;
    },
    abortController?: CustomAbortController,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      return new Promise((resolve, reject): void => {
        if (abortController) {
          abortController.instance.signal.onabort = (e): void => {
            // @ts-ignore
            if (e.currentTarget?.reason === ABORT_TOOL_EXECUTION_KEY) {
              callback.onClose && callback.onClose();
              resolve();
            }
          };
        }
        fetchEventSource(
          `${baseApiClient.baseUrl}/api/v1/tools/${messageData.tool_id}/execute/stream/chunked`,
          {
            method: 'POST',
            openWhenHidden: true,
            signal: abortController?.instance.signal,
            headers: {
              // @ts-ignore
              'Access-Token': params?.headers['Access-Token'] || '',
            },
            body: JSON.stringify(messageData.form_data),
            async onopen(response) {
              if (
                response.ok &&
                response.headers.get('content-type') === EventStreamContentType
              ) {
                return; // everything's good
              } else if (response.status === 401 || response.status === 403) {
                abortController?.instance.abort();
                abortController?.resetInstance();
                reject(new FatalError('Not Authorized'));
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                try {
                  const body = await response.json();
                  throw new FatalError(body.error);
                } catch (e: any) {
                  abortController?.instance.abort();
                  abortController?.resetInstance();
                  reject(new FatalError(e.message || ''));
                }
              }
            },
            onclose() {
              resolve();
            },
            onmessage(event) {
              if (!event.data) {
                return;
              }
              const data = JSON.parse(event.data);
              if (!data.event_type) {
                return;
              }

              switch (data.event_type) {
                case ModelsChatEvent.EventToolExecutionCreated:
                  callback.onCreate &&
                    callback.onCreate(
                      data?.data.execution_results.map((item) =>
                        PlatformApi.mapToolExecutionResponse(item),
                      ),
                    );
                  break;
                case ModelsChatEvent.EventToolExecutionChunk:
                  callback.onChunkReceive &&
                    callback.onChunkReceive({
                      id: data?.data.tool_execution_response_id || '',
                      text: data?.data.chunk || '',
                    });
                  break;
                case ModelsChatEvent.EventToolExecutionComplete:
                  callback.onComplete(
                    data?.data.execution_results.map((item) =>
                      PlatformApi.mapToolExecutionResponse(item),
                    ),
                  );
                  break;
              }
            },
          },
        );
      });
    }, 'sendToolFormData');
  }

  async generateToolItemAiEdit(
    toolId: string,
    itemId: string,
    action: string,
    callback: {
      onComplete: (item: AiEditType) => void;
      onChunkReceive?: (item: AiEditType) => void;
      onClose?: () => void;
    },
    abortController?: CustomAbortController,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      return new Promise((resolve, reject): void => {
        if (abortController) {
          abortController.instance.signal.onabort = (e): void => {
            // @ts-ignore
            if (e.currentTarget?.reason === ABORT_TOOL_AI_EDIT_KEY) {
              callback.onClose && callback.onClose();
              resolve();
            }
          };
        }
        fetchEventSource(
          `${baseApiClient.baseUrl}/api/v1/tools/${toolId}/results/${itemId}/ai_edit`,
          {
            method: 'POST',
            openWhenHidden: true,
            signal: abortController?.instance.signal,
            headers: {
              // @ts-ignore
              'Access-Token': params?.headers['Access-Token'] || '',
            },
            body: JSON.stringify({ action }),
            async onopen(response) {
              if (
                response.ok &&
                response.headers.get('content-type') === EventStreamContentType
              ) {
                return; // everything's good
              } else if (response.status === 401 || response.status === 403) {
                abortController?.instance.abort();
                abortController?.resetInstance();
                reject(new FatalError('Not Authorized'));
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                try {
                  const body = await response.json();
                  throw new FatalError(body.error);
                } catch (e: any) {
                  abortController?.instance.abort();
                  abortController?.resetInstance();
                  reject(new FatalError(e.message || ''));
                }
              }
            },
            onclose() {
              resolve();
            },
            onmessage(event) {
              if (!event.data) {
                return;
              }
              const data = JSON.parse(event.data);
              if (!data.event_type) {
                return;
              }

              switch (data.event_type) {
                case ModelsChatEvent.AiEditResponseChunkReady:
                  callback.onChunkReceive &&
                    callback.onChunkReceive({
                      text: data?.data.chunk || '',
                      id: data?.data.ai_edit_id || '',
                      reaction: data.data.user_reaction || 0,
                    });
                  break;
                case ModelsChatEvent.AiEditResponse:
                  callback.onComplete({
                    id: data.data.id || '',
                    text: data.data.ai_edit_result || '',
                    reaction: data.data.user_reaction || '',
                  });
                  break;
              }
            },
          },
        );
      });
    }, 'generateToolItemAiEdit');
  }

  async getToolExecutionsByToolId(
    toolId: string,
    {
      query,
    }: {
      query: {
        page?: number;
        per_page?: number;
        sort_by?: string;
        sort_type?: string;
      };
    },
  ): Promise<{
    data: ToolExecutionType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ToolExecutionType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1ToolsExecutionsDetail(
        toolId,
        query,
        params,
      );

      if (data) {
        const items = data.data;
        const meta = data.meta;
        return {
          data:
            items && items.length
              ? items.map((item) => PlatformApi.mapToolExecutionResponse(item))
              : [],
          meta: {
            currentPage: meta?.current_page || query.page,
            pageSize: meta?.page_size || 20,
            totalCount: meta?.total_count || 0,
            totalPages: meta?.total_pages || 1,
          },
        };
      }
      throw Error('Invalid response');
    }, 'getToolExecutionsByToolId');
  }

  async getImprovedImageGenerationPrompt(prompt: string): Promise<string> {
    return this.makeAuthorizedRequest<string>(async (params) => {
      const data =
        await baseApiClient.api.v1ToolsImageGeneratorImprovePromptCreate(
          { instruction: prompt },
          params,
        );
      if (data.data.data) {
        return data.data.data.improved_prompt || '';
      }
      throw Error('Invalid response');
    }, 'getImprovedImageGenerationPrompt');
  }

  async setImageGenerationReaction(
    itemId: string,
    reaction: REACTION,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ToolsImageGeneratorGenerationsReactionCreate(
        itemId,
        { reaction },
        params,
      );
    }, 'setImageGenerationReaction');
  }

  async getImageGeneratedItems({
    query,
  }: {
    query: {
      page?: number;
      per_page?: number;
      sort_by?: string;
      sort_type?: string;
    };
  }): Promise<{
    data: ImageGenerationExecutionItemType[];
    meta: MetaDataResponseType;
  }> {
    return this.makeAuthorizedRequest<{
      data: ImageGenerationExecutionItemType[];
      meta: MetaDataResponseType;
    }>(async (params) => {
      const { data } =
        await baseApiClient.api.v1ToolsImageGeneratorGenerationsList(
          query,
          params,
        );

      if (data) {
        const items = data.data;
        const meta = data.meta;
        return {
          data:
            items && items.length
              ? items.map((item) =>
                  PlatformApi.mapImageGenerationExecutionResponse(item),
                )
              : [],
          meta: {
            currentPage: meta?.current_page || query.page,
            pageSize: meta?.page_size || 20,
            totalCount: meta?.total_count || 0,
            totalPages: meta?.total_pages || 1,
          },
        };
      }
      throw Error('Invalid response');
      // throw Error('Invalid response in getImageGeneratedItems');
    }, 'getImageGeneratedItems');
  }

  async sendImageGenerationForm(
    formData: ImageGenerationFormDataType,
    callback: {
      onComplete: (message: ImageGenerationExecutionItemType) => void;
      onClose?: () => void;
    },
    abortController?: CustomAbortController,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      return new Promise((resolve, reject): void => {
        if (abortController) {
          abortController.instance.signal.onabort = (e): void => {
            // @ts-ignore
            if (e.currentTarget?.reason === ABORT_TOOL_EXECUTION_KEY) {
              callback.onClose && callback.onClose();
              resolve();
            }
          };
        }
        fetchEventSource(
          `${baseApiClient.baseUrl}/api/v1/tools/image-generator/generate`,
          {
            method: 'POST',
            openWhenHidden: true,
            signal: abortController?.instance.signal,
            headers: {
              // @ts-ignore
              'Access-Token': params?.headers['Access-Token'] || '',
            },
            body: JSON.stringify({
              hd_quality: formData.hd_quality,
              image_size: formData.size,
              instruction: formData.instruction,
              style: formData.style,
            }),
            async onopen(response) {
              if (
                response.ok &&
                response.headers.get('content-type') === EventStreamContentType
              ) {
                return; // everything's good
              } else if (response.status === 401 || response.status === 403) {
                abortController?.instance.abort();
                abortController?.resetInstance();
                reject(new FatalError('Not Authorized'));
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                try {
                  const body = await response.json();
                  if (body.fields) {
                    throw new FieldsError(body);
                  }
                  throw new FatalError(body.error);
                } catch (e: any) {
                  abortController?.instance.abort();
                  abortController?.resetInstance();
                  if (e instanceof FieldsError) {
                    reject(e);
                  }
                  reject(new FatalError(e.message || ''));
                }
              }
            },
            onclose() {
              resolve();
            },
            onmessage(event) {
              if (!event.data) {
                return;
              }
              const data = JSON.parse(event.data);
              if (data.error) {
                reject(new FatalError(data.error));
                return;
              }

              if (!data.event_type) {
                return;
              }

              switch (data.event_type) {
                case ModelsChatEvent.ImageGenerationComplete:
                  callback.onComplete(
                    PlatformApi.mapImageGenerationExecutionResponse(data.data),
                  );
                  break;
              }
            },
          },
        );
      });
    }, 'sendImageGenerationForm');
  }

  async getPlagiarismCheckerFile(id: string): Promise<any> {
    return this.makeAuthorizedRequest<any>(async (params) => {
      const data = await baseApiClient.api.v1CopyleaksExportsPdfDetail(
        id,
        params,
      );

      if (data.ok) {
        return data;
      }

      throw Error('Error during file download');
    }, 'getPlagiarismCheckerFile');
  }

  async sendCopyLeaksReaction(id: string, reaction: REACTION): Promise<any> {
    return this.makeAuthorizedRequest<any>(async (params) => {
      const data = await baseApiClient.api.v1CopyleaksExportsReactionCreate(
        id,
        { reaction },
        params,
      );

      if (!data.ok) {
        throw Error('Error during send reaction');
      }
    }, 'sendCopyLeaksReaction');
  }

  async clearImageGenerationHistory(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ToolsImageGeneratorGenerationsDelete(params);
    }, 'clearImageGenerationHistory');
  }

  async sendAiHumanizerReaction(id: string, reaction: REACTION): Promise<any> {
    return this.makeAuthorizedRequest<any>(async (params) => {
      const data =
        await baseApiClient.api.v1AiHumanizerExecutionsReactionCreate(
          id,
          { reaction },
          params,
        );

      if (!data.ok) {
        throw Error('Error during send reaction');
      }
    }, 'sendAiHumanizerReaction');
  }

  async getAiHumanizerExecutions({
    query,
  }: {
    query: {
      page?: number;
      per_page?: number;
      sort_by?: string;
      sort_type?: string;
    };
  }): Promise<{
    data: AiHumanizerExecutionType[];
    meta: MetaType;
  }> {
    return this.makeAuthorizedRequest<{
      data: AiHumanizerExecutionType[];
      meta: MetaType;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1AiHumanizerExecutionsList(
        query,
        params,
      );

      if (data) {
        const items: any = data.data;
        const meta = data.meta;

        return {
          data:
            items && items.length
              ? items.map((item) => {
                  return PlatformApi.mapAiExecutionResponse(item);
                })
              : [],
          meta: {
            currentPage: meta?.current_page || query.page,
            pageSize: meta?.page_size || 20,
            totalCount: meta?.total_count || 0,
            totalPages: meta?.total_pages || 1,
          },
        };
      }
      throw Error('Invalid response in getAiHumanizerExecutions');
    }, 'getAiHumanizerExecutions');
  }

  async updateAiHumanizerExecution(
    resultId: string,
    dataToUpdate: {
      ai_response: string;
    },
  ): Promise<AiHumanizerExecutionType> {
    return this.makeAuthorizedRequest<AiHumanizerExecutionType>(
      async (params) => {
        const { data } = await baseApiClient.api.v1AiHumanizerExecutionsUpdate(
          resultId,
          dataToUpdate,
          params,
        );

        if (data.data) {
          return PlatformApi.mapAiExecutionResponse(data.data);
        }
        throw Error('Invalid response');
      },
      'updateAiHumanizerExecution',
    );
  }

  async deleteAiHumanizerExecutions(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      const response =
        await baseApiClient.api.v1AiHumanizerExecutionsDelete(params);

      if (response.status !== 200) {
        throw Error('Invalid response');
      }
    }, 'deleteAiHumanizerExecutions');
  }

  async sendAiHumanizerData(
    data: {
      original_text: string;
      purpose: string;
      readability: string;
      mode: string;
    },
    is_freemium_request?: boolean,
  ): Promise<AiHumanizerExecutionExtendedType> {
    return this.makeAuthorizedRequest<AiHumanizerExecutionExtendedType>(
      async (params) => {
        return new Promise((resolve, reject): void => {
          const controller = new AbortController();
          fetchEventSource(
            `${baseApiClient.baseUrl}/api/v1/ai-humanizer${is_freemium_request ? '?is_freemium_request=true' : ''}`,
            {
              method: 'POST',
              openWhenHidden: true,
              signal: controller.signal,
              headers: {
                // @ts-ignore
                'Access-Token': params?.headers['Access-Token'] || '',
              },
              body: JSON.stringify(data),
              async onopen(response) {
                if (
                  response.ok &&
                  response.headers.get('content-type') ===
                    EventStreamContentType
                ) {
                  return; // everything's good
                } else if (response.status === 401 || response.status === 403) {
                  controller.abort();
                  reject(new FatalError('Not Authorized'));
                } else if (
                  response.status >= 400 &&
                  response.status < 500 &&
                  response.status !== 429
                ) {
                  try {
                    const body = await response.json();
                    if (body.fields) {
                      throw new FieldsError(body);
                    }
                    throw new FatalError(body.error);
                  } catch (e: any) {
                    controller.abort();
                    if (e instanceof FieldsError) {
                      reject(e);
                    }
                    reject(new FatalError(e.message || ''));
                  }
                }
              },
              onmessage(event) {
                if (!event.data) {
                  return;
                }
                const data = JSON.parse(event.data);
                if (data.error) {
                  controller.abort();
                  reject(new StreamVendorError(data.error));
                }
                if (!data.event_type) {
                  return;
                }

                switch (data.event_type) {
                  case ModelsChatEvent.EventAIHumanizerProcessingComplete:
                    resolve({
                      ...data?.data,
                      id: data?.data.id,
                      request_message: data?.data.original_text,
                      response_message: data?.data.humanized_text,
                    });
                    break;
                }
              },
            },
          );
        });
      },
      'sendAiHumanizerData',
    );
  }

  async getAiHumanizer(): Promise<AiHumanizerType> {
    return this.makeAuthorizedRequest<AiHumanizerType>(async (params) => {
      const { data } = await baseApiClient.api.v1AiHumanizerList(params);

      if (data.data) {
        const tool = data.data;

        return {
          id: tool.id || '',
          title: tool.title || '',
          regular_words_total: tool.regular_words_total || 0,
          regular_words_left: tool.regular_words_left || 0,
          extra_words_total: tool.extra_words_total || 0,
          extra_words_left: tool.extra_words_left || 0,
          last_inputs: tool.last_inputs || {},
          ai_edit_options: [
            ...(tool.ai_edit_actions?.map(({ action, name }) => ({
              text: name || '',
              value: (action || '') as string,
            })) || []),
            ...(tool.allow_manual_edit
              ? [{ text: 'Manual edit', value: 'manual_edit' }]
              : []),
          ],
          permissions: tool.is_limited ? [] : [TOOL_PERMISSION.READ],
        };
      }

      throw Error('Invalid response in getAiHumanizer');
    }, 'getAiHumanizer');
  }

  async getPlagiarismCheckerExecutions({
    query,
  }: {
    query: {
      page?: number;
      per_page?: number;
      sort_by?: string;
      sort_type?: string;
    };
  }): Promise<{
    data: PlagiarismExecutionType[];
    meta: MetaType;
  }> {
    return this.makeAuthorizedRequest<{
      data: PlagiarismExecutionType[];
      meta: MetaType;
    }>(async (params) => {
      const { data } =
        await baseApiClient.api.v1CopyleaksPlagiarismCheckExecutionsList(
          query,
          params,
        );

      if (data) {
        const items: any = data.data;
        const meta = data.meta;
        return {
          data:
            items && items.length
              ? items.map(PlatformApi.mapPlagiarismExecutionResponse)
              : [],
          meta: {
            currentPage: meta?.current_page || query.page,
            pageSize: meta?.page_size || 20,
            totalCount: meta?.total_count || 0,
            totalPages: meta?.total_pages || 1,
          },
        };
      }
      throw Error('Invalid response in getPlagiarismCheckerExecutions');
    }, 'getPlagiarismCheckerExecutions');
  }

  async deletePlagiarismCheckerExecutions(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      const response =
        await baseApiClient.api.v1CopyleaksPlagiarismCheckExecutionsDelete(
          params,
        );

      if (response.status !== 200) {
        throw Error('Invalid response');
      }
    }, 'deletePlagiarismCheckerExecutions');
  }

  async sendPlagiarismCheckerData(
    file_content: string,
    is_sandbox?: boolean,
  ): Promise<ExtendPlagiarismExecutionType> {
    return this.makeAuthorizedRequest<ExtendPlagiarismExecutionType>(
      async (params) => {
        return new Promise((resolve, reject): void => {
          const controller = new AbortController();
          fetchEventSource(
            `${baseApiClient.baseUrl}/api/v1/copyleaks/plagiarism-check`,
            {
              method: 'POST',
              openWhenHidden: true,
              signal: controller.signal,
              headers: {
                // @ts-ignore
                'Access-Token': params?.headers['Access-Token'] || '',
              },

              body: JSON.stringify({ file_content, is_sandbox }),
              async onopen(response) {
                if (
                  response.ok &&
                  response.headers.get('content-type') ===
                    EventStreamContentType
                ) {
                  return; // everything's good
                } else if (response.status === 401 || response.status === 403) {
                  controller.abort();
                  reject(new FatalError('Not Authorized'));
                } else if (
                  response.status >= 400 &&
                  response.status < 500 &&
                  response.status !== 429
                ) {
                  try {
                    const body = await response.json();
                    if (body.fields) {
                      throw new FieldsError(body);
                    }
                    throw new FatalError(body.error);
                  } catch (e: any) {
                    controller.abort();
                    if (e instanceof FieldsError) {
                      reject(e);
                    }
                    reject(new FatalError(e.message || ''));
                  }
                }
              },
              onmessage(event) {
                if (!event.data) {
                  return;
                }
                const data = JSON.parse(event.data);
                if (!data.event_type) {
                  return;
                }

                switch (data.event_type) {
                  case ModelsChatEvent.EventToolExecutionComplete:
                    resolve({
                      ...PlatformApi.mapPlagiarismExecutionResponse,
                      ...data?.data,
                    });
                    break;
                }
              },
            },
          );
        });
      },
      'sendPlagiarismCheckerData',
    );
  }

  async getPlagiarismChecker(): Promise<PlagiarismCheckerType> {
    return this.makeAuthorizedRequest<PlagiarismCheckerType>(async (params) => {
      const { data } =
        await baseApiClient.api.v1CopyleaksPlagiarismCheckList(params);

      if (data.data) {
        const item = data.data;
        return {
          id: item.id || '',
          title: item.title || '',
          regular_words_total: item.regular_words_total || 0,
          regular_words_left: item.regular_words_left || 0,
          extra_words_total: item.extra_words_total || 0,
          extra_words_left: item.extra_words_left || 0,
          last_inputs: {
            ...item.last_inputs,
            text: item.last_inputs.file_content || '',
          },
          permissions: item.is_limited ? [] : [TOOL_PERMISSION.READ],
        };
      }

      throw Error('Invalid response in getPlagiarismChecker');
    }, 'getPlagiarismChecker');
  }

  async getAiDetectorExecutions({
    query,
  }: {
    query: {
      page?: number;
      per_page?: number;
      sort_by?: string;
      sort_type?: string;
    };
  }): Promise<{
    data: AiDetectorExecutionType[];
    meta: MetaType;
  }> {
    return this.makeAuthorizedRequest<{
      data: AiDetectorExecutionType[];
      meta: MetaType;
    }>(async (params) => {
      const { data } =
        await baseApiClient.api.v1ZerogptAiDetectorExecutionsList(
          query,
          params,
        );

      if (data) {
        const items: any = data.data;
        const meta = data.meta;
        return {
          data:
            items && items.length
              ? items.map(PlatformApi.mapAiDetectorExecutionResponse)
              : [],
          meta: {
            currentPage: meta?.current_page || query.page,
            pageSize: meta?.page_size || 20,
            totalCount: meta?.total_count || 0,
            totalPages: meta?.total_pages || 1,
          },
        };
      }
      throw Error('Invalid response in getAiDetectorExecutions');
    }, 'getAiDetectorExecutions');
  }

  async deleteAiDetectorExecutions(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      const response =
        await baseApiClient.api.v1ZerogptAiDetectorExecutionsDelete(params);

      if (response.status !== 200) {
        throw Error('Invalid response');
      }
    }, 'deleteAiDetectorExecution');
  }

  async sendAiDetectorData(
    file_content: string,
  ): Promise<ExtendAiDetectorExecutionType> {
    return this.makeAuthorizedRequest<ExtendAiDetectorExecutionType>(
      async (params) => {
        const { data } = await baseApiClient.api.v1ZerogptAiDetectorCreate(
          {
            input: file_content,
          },
          params,
        );

        if (data.data) {
          const item = data.data;

          return {
            ...PlatformApi.mapAiDetectorExecutionResponse(item),
            regular_words_total: item.regular_words_total || 0,
            regular_words_left: item.regular_words_left || 0,
            extra_words_total: item.extra_words_total || 0,
            extra_words_left: item.extra_words_left || 0,
          };
        }

        throw Error('Invalid response in sendAiDetectorData');
      },
      'sendAiDetectorData',
    );
  }

  async getAiDetector(): Promise<AiDetectorType> {
    return this.makeAuthorizedRequest<AiDetectorType>(async (params) => {
      const { data } = await baseApiClient.api.v1ZerogptAiDetectorList(params);

      if (data.data) {
        const item = data.data;
        return {
          id: item.id,
          free_humanize_count: item.free_humanize_count
            ? item.free_humanize_count
            : 0,
          title: item.title,
          regular_words_total: item.regular_words_total || 0,
          regular_words_left: item.regular_words_left || 0,
          extra_words_total: item.extra_words_total || 0,
          extra_words_left: item.extra_words_left || 0,
          last_inputs: {
            ...item.last_inputs,
            text: item.last_inputs.file_content || '',
          },
          permissions: item.is_limited ? [] : [TOOL_PERMISSION.READ],
        };
      }

      throw Error('Invalid response in getAiDetector');
    }, 'getAiDetector');
  }

  async generateMoreByToolExecutionId(
    toolId: string,
    resultId: string,
    executionId: string,
  ): Promise<ToolExecutionType> {
    return this.makeAuthorizedRequest<ToolExecutionType>(async (params) => {
      const { data } =
        await baseApiClient.api.v1ToolsExecutionsResultsGenerateMoreCreate(
          toolId,
          executionId,
          resultId,
          params,
        );

      if (data && data.data) {
        return PlatformApi.mapToolExecutionResponse(data.data);
      }
      throw Error('Invalid response');
    }, 'generateMoreByToolExecutionId');
  }

  async getSubscription(): Promise<SubscriptionType> {
    return this.makeAuthorizedRequest<SubscriptionType>(async (params) => {
      const { data } = await baseApiClient.api.v1SubscriptionList(params);
      const subscription = data.data;
      if (subscription) {
        return PlatformApi.mapSubscriptionResponse(subscription);
      }
      throw Error('Invalid response in getSubscription');
    }, 'getSubscription');
  }

  async getProductList(): Promise<ProductType[]> {
    return this.makeAuthorizedRequest<ProductType[]>(async (params) => {
      const { data } = await baseApiClient.api.v1ProductsList(params);
      const products = data.data;
      if (products) {
        return products
          .map((product) => PlatformApi.mapProductResponse(product))
          .filter(({ words_amount }) => words_amount);
      }
      throw Error('Invalid response in getProductList');
    }, 'getProductList');
  }

  async getProductDownsellList(): Promise<ProductType[]> {
    return this.makeAuthorizedRequest<ProductType[]>(async (params) => {
      const { data } = await baseApiClient.api.v1ProductsDownsellList(params);
      const products = data.data;

      if (products) {
        return products.map((product) =>
          PlatformApi.mapProductResponse(product),
        );
      }

      if (products === null) {
        return [];
      }

      throw Error('Invalid response');
    }, 'getProductDownsellList');
  }

  async getProductById(id: string): Promise<ProductType> {
    return this.makeAuthorizedRequest<ProductType>(async (params) => {
      const { data } = await baseApiClient.api.v1ProductsDetail(id, params);
      const product = data.data;
      if (product) {
        return product;
      }
      throw Error('Invalid response in getProductById');
    }, 'getProductById');
  }

  async changePassword(
    old_password: string,
    new_password: string,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1PasswordChangeCreate(
        { old_password, new_password },
        params,
      );
    }, 'changePassword');
  }

  async updateProfile(payload: Record<string, any>): Promise<ProfileType> {
    return this.makeAuthorizedRequest<ProfileType>(async (params) => {
      const { data } = await baseApiClient.api.v1ProfileUpdate(payload, params);
      if (data.data) {
        return PlatformApi.mapProfileResponse(data.data);
      }
      throw Error('Invalid response in updateProfile');
    }, 'updateProfile');
  }

  async deleteAccount(user_id: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      const sandbox = this.getPaymentSandboxKey(user_id);
      await baseApiClient.api.v1ProfileDelete({
        ...params, // @ts-ignore
        query: sandbox ? { sandbox } : {},
      });
    }, 'deleteAccount');
  }

  async updateOnboardingData(
    data: Partial<Record<ONBOARDING_SAVE_KEYS, string>>,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1AuthOnboardingV2Create(data, params);
    }, 'updateOnboardingData');
  }

  async uploadProfileAvatar(file: File): Promise<string> {
    return this.makeAuthorizedRequest<string>(async (params) => {
      const { data } = await baseApiClient.api.v1ProfileAvatarCreate(
        { file },
        params,
      );
      if (data?.data?.avatar_url) {
        return data.data.avatar_url;
      }
      throw Error('Invalid response');
    }, 'uploadProfileAvatar');
  }

  async removeProfileAvatar(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ProfileAvatarDelete(params);
    }, 'removeProfileAvatar');
  }

  async getUsageStatistics(): Promise<UsageStatisticsType> {
    return this.makeAuthorizedRequest<UsageStatisticsType>(async (params) => {
      const { data } =
        await baseApiClient.api.v1ProfileUsageStatisticsList(params);

      if (data.data) {
        return {
          user_last_tool_name: data.data.user_last_tool_name || '',
          user_popular_tool_name: data.data.user_popular_tool_name || '',
          user_last_tools:
            data.data.user_last_tools?.map((item) =>
              PlatformApi.mapTemplateResponse(item),
            ) || [],
        };
      }
      throw Error('Invalid response');
    }, 'getUsageStatistics');
  }

  async restoreSubscription(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1SubscriptionRestoreCreate(params);
    }, 'restoreSubscription');
  }

  async restoreSubscriptionHard(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      return new Promise((resolve, reject): void => {
        const controller = new AbortController();
        fetchEventSource(
          `${baseApiClient.baseUrl}/api/v1/subscription/restore/hard`,
          {
            method: 'POST',
            openWhenHidden: true,
            signal: controller.signal,
            headers: {
              // @ts-ignore
              'Access-Token': params?.headers['Access-Token'] || '',
            },

            async onopen(response) {
              if (
                response.ok &&
                response.headers.get('content-type') === EventStreamContentType
              ) {
                return; // everything's good
              } else if (response.status === 401 || response.status === 403) {
                controller.abort();
                reject(new FatalError('Not Authorized'));
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                try {
                  const body = await response.json();
                  if (body.fields) {
                    throw new FieldsError(body);
                  }
                  throw new FatalError(body.error);
                } catch (e: any) {
                  controller.abort();
                  if (e instanceof FieldsError) {
                    reject(e);
                  }
                  reject(new FatalError(e.message || ''));
                }
              }
            },
            onmessage(event) {
              if (!event.data) {
                return;
              }

              const data = JSON.parse(event.data);

              if (data.error) {
                reject();
              }

              if (!data.event_type) {
                return;
              }

              switch (data.event_type) {
                case ModelsChatEvent.RedemptionFixPaymentSuccessComplete:
                  resolve();
                  break;

                case ModelsChatEvent.RedemptionFixPaymentFailedComplete:
                  reject();
                  break;
              }
            },
          },
        );
      });
    }, 'restoreSubscriptionHard');
  }

  async cancelSubscription(
    body: {
      feedback: string;
      reason: string;
      notification_agreement?: boolean;
    },
    user_id: string,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      const sandbox = this.getPaymentSandboxKey(user_id);
      await baseApiClient.api.v1SubscriptionCancelCreate(body, {
        ...params,
        // @ts-ignore
        query: sandbox ? { sandbox } : {},
      });
    }, 'cancelSubscription');
  }

  async pauseSubscription(body: {
    feedback?: string;
    pause_amount: number;
    pause_unit: 'day' | 'week' | 'month';
    reason: string;
  }): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1SubscriptionPauseCreate(body, params);
    }, 'pauseSubscription');
  }

  async unpauseSubscription(): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1SubscriptionPauseDelete(params);
    }, 'unpauseSubscription');
  }

  async checkUserPassword(password: string): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1PasswordConfirmCreate({ password }, params);
    }, 'checkUserPassword');
  }

  async sendOffboardingSupportFeedback(
    feedback: OffboardingSupportFeedback,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1OffboardingSupportCreate(feedback, params);
    }, 'sendOffboardingSupportFeedback');
  }

  async createFunnelUser(
    email: string,
  ): Promise<{ id: string; email: string }> {
    const { data } = await baseApiClient.api.v1FunnelsUsersCreate({
      email: email.trim(),
    });
    if (data.data) {
      return { id: data.data.id, email: data.data.email };
    }
    throw Error('Invalid response');
  }

  async createFunnelPaymentIntent(
    user_id: string,
    product_id: string,
    geo_country?: string,
  ): Promise<{
    merchant: string;
    payment_intent: string;
    signature: string;
  }> {
    const sandbox = this.getPaymentSandboxKey(user_id);

    const { data } = await baseApiClient.api.v1FunnelsUsersPaymentsIntentCreate(
      user_id,
      {
        product_id,
        geo_country,
        ...(sandbox ? { sandbox } : {}),
      },
    );
    if (data.data) {
      return {
        merchant: data.data.merchant,
        payment_intent: data.data.payment_intent,
        signature: data.data.signature,
      };
    }
    throw Error('Invalid response');
  }

  async createPaymentIntentCard(
    user_id: string,
    product_id: string,
    geo_country: string,
  ): Promise<{
    merchant: string;
    payment_intent: string;
    signature: string;
  }> {
    return this.makeAuthorizedRequest<{
      merchant: string;
      payment_intent: string;
      signature: string;
    }>(async (params) => {
      const sandbox = this.getPaymentSandboxKey(user_id);

      const { data } = await baseApiClient.api.v1ProductsPaymentsIntentCreate(
        product_id,
        {
          geo_country,
          ...(sandbox ? { sandbox } : {}),
        },
        params,
      );
      if (data.data) {
        return {
          merchant: data.data.merchant,
          payment_intent: data.data.payment_intent,
          signature: data.data.signature,
        };
      }
      throw Error('Invalid response in createPaymentIntentCard');
    }, 'createPaymentIntentCard');
  }

  async updatePaymentMethodCard(geo_country: string): Promise<{
    merchant: string;
    payment_intent: string;
    signature: string;
  }> {
    return this.makeAuthorizedRequest<{
      merchant: string;
      payment_intent: string;
      signature: string;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1PaymentsUpdateCardCreate(
        {
          geo_country,
        },
        params,
      );

      if (data.data) {
        return {
          merchant: data.data.merchant,
          payment_intent: data.data.payment_intent,
          signature: data.data.signature,
        };
      }
      throw Error('Invalid response in updatePaymentMethodCard');
    }, 'updatePaymentMethodCard');
  }

  async createPaymentIntentPaypal(
    user_id: string,
    product_id: string,
  ): Promise<{
    order: Record<string, any>;
    order_metadata: any;
    pay_form: Record<string, any>;
  }> {
    const sandbox = this.getPaymentSandboxKey(user_id);

    return this.makeAuthorizedRequest<{
      order: Record<string, any>;
      order_metadata: any;
      pay_form: Record<string, any>;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1ProductsPaymentsPaypalCreate(
        product_id,
        {
          ...params,
          // @ts-ignore
          query: sandbox ? { sandbox } : {},
        },
      );
      if (data.data) {
        return {
          order: data.data.order,
          order_metadata: data.data.order_metadata,
          pay_form: data.data.pay_form,
        };
      }
      throw Error('Invalid response in createPaymentIntentPaypal');
    }, 'createPaymentIntentPaypal');
  }

  async updatePaymentMethodPaypal(): Promise<{
    order: Record<string, any>;
    order_metadata: any;
    pay_form: Record<string, any>;
  }> {
    return this.makeAuthorizedRequest<{
      order: Record<string, any>;
      order_metadata: any;
      pay_form: Record<string, any>;
    }>(async (params) => {
      const { data } = await baseApiClient.api.v1PaymentsUpdatePaypalCreate({
        ...params,
      });

      if (data.data) {
        return {
          order: data.data.order,
          order_metadata: data.data.order_metadata,
          pay_form: data.data.pay_form,
        };
      }
      throw Error('Invalid response in updatePaymentMethodPaypal');
    }, 'updatePaymentMethodPaypal');
  }

  async createChatFeedback(
    formData: Partial<{
      understand_rate: number;
      experience_rate: number;
      responsiveness_rate: number;
      excitement_about: string;
      improvements: string;
      contact_consent: boolean;
    }>,
  ): Promise<{ id: string }> {
    return this.makeAuthorizedRequest<{ id: string }>(async (params) => {
      const { data } = await baseApiClient.api.v1ChatsFeedbacksCreate(
        {
          chat_understanding_rate: formData.understand_rate ?? undefined,
          experience_rate: formData.experience_rate ?? undefined,
          responsiveness_rate: formData.responsiveness_rate ?? undefined,
          favorite_chat_feature_aspect: formData.excitement_about ?? undefined,
          suggested_chat_improvements: formData.improvements ?? undefined,
          contact_consent: formData.contact_consent ?? undefined,
        },
        params,
      );

      if (data.data.id) {
        return {
          id: data.data.id,
        };
      }

      throw Error('Invalid response');
    }, 'createChatFeedback');
  }

  async updateChatFeedback(
    feedback_id: string,
    formData: Partial<{
      understand_rate: number;
      experience_rate: number;
      responsiveness_rate: number;
      excitement_about: string;
      improvements: string;
      contact_consent: boolean;
    }>,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ChatsFeedbacksUpdate(
        feedback_id,
        {
          favorite_chat_feature_aspect: formData.excitement_about ?? undefined,
          suggested_chat_improvements: formData.improvements ?? undefined,
          chat_understanding_rate: formData.understand_rate ?? undefined,
          experience_rate: formData.experience_rate ?? undefined,
          responsiveness_rate: formData.responsiveness_rate ?? undefined,
          contact_consent: formData.contact_consent ?? undefined,
        },
        params,
      );
    }, 'updateChatFeedback');
  }

  async setChatMessageReaction(
    chatId: string,
    messageId: string,
    reaction: REACTION,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ChatsMessagesReactionCreate(
        chatId,
        messageId,
        {
          reaction,
        },
        params,
      );
    }, 'setChatMessageReaction');
  }

  async markChatMessageCopied(
    chatId: string,
    messageId: string,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ChatsMessagesMarkCopiedCreate(
        chatId,
        messageId,
        params,
      );
    }, 'createChatMessageCopied');
  }

  async markToolsExecutionsResultsCopied(
    toolId: string,
    resultId: string,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ToolsResultsMarkCopiedCreate(
        toolId,
        resultId,
        params,
      );
    }, 'createToolsExecutionsResultsCopied');
  }

  async markCopyleaksExecutionsResultsCopied(
    executionId: string,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1CopyleaksExportsCopyCreate(executionId, params);
    }, 'markCopyleaksExecutionsResultsCopied');
  }

  async markAiHumanizerExecutionsResultsCopied(
    executionId: string,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1AiHumanizerExecutionsCopyCreate(
        executionId,
        params,
      );
    }, 'markAiHumanizerExecutionsResultsCopied');
  }

  async markToolsAiEditCopied(
    aiEditId: string,
    toolId: string,
    resultId: string,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ToolsResultsAiEditMarkCopiedCreate(
        aiEditId,
        toolId,
        resultId,
        params,
      );
    }, 'markToolsAiEditCopied');
  }

  async setToolResponseReaction(
    toolId: string,
    resultId: string,
    reaction: REACTION,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ToolsResultsReactionCreate(
        toolId,
        resultId,
        {
          reaction,
        },
        params,
      );
    }, 'setToolResponseReaction');
  }

  async setToolAiEditReaction(
    toolId: string,
    resultId: string,
    aiEditId: string,
    reaction: REACTION,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ToolsResultsAiEditReactionCreate(
        aiEditId,
        toolId,
        resultId,
        {
          reaction,
        },
        params,
      );
    }, 'setToolResponseReaction');
  }

  async createToolsFeedback(
    tool_id: string,
    formData: Partial<{
      understand_rate: number;
      experience_rate: number;
      responsiveness_rate: number;
      excitement_about: string;
      improvements: string;
      contact_consent: boolean;
    }>,
  ): Promise<{ id: string }> {
    return this.makeAuthorizedRequest<{ id: string }>(async (params) => {
      const { data } = await baseApiClient.api.v1ToolsFeedbacksCreate(
        tool_id,
        {
          excited_about: formData.excitement_about ?? undefined,
          experience_rate: formData.experience_rate ?? undefined,
          responsiveness_rate: formData.responsiveness_rate ?? undefined,
          suggested_improvements: formData.improvements ?? undefined,
          tool_understanding_rate: formData.understand_rate ?? undefined,
          contact_consent: formData.contact_consent ?? undefined,
        },
        params,
      );

      if (data.data.id) {
        return {
          id: data.data.id,
        };
      }

      throw Error('Invalid response');
    }, 'createToolsFeedback');
  }

  async updateToolsFeedback(
    tool_id: string,
    feedback_id: string,
    formData: Partial<{
      understand_rate: number;
      experience_rate: number;
      responsiveness_rate: number;
      excitement_about: string;
      improvements: string;
      contact_consent: boolean;
    }>,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ToolsFeedbacksUpdate(
        feedback_id,
        tool_id,
        {
          excited_about: formData.excitement_about ?? undefined,
          experience_rate: formData.experience_rate ?? undefined,
          responsiveness_rate: formData.responsiveness_rate ?? undefined,
          suggested_improvements: formData.improvements ?? undefined,
          tool_understanding_rate: formData.understand_rate ?? undefined,
          contact_consent: formData.contact_consent ?? undefined,
        },
        params,
      );
    }, 'updateToolsFeedback');
  }

  async createNeedToolFeedback(
    formData: Partial<{
      name: string;
      description: string;
      contact_consent: boolean;
    }>,
  ): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1ToolsFeedbacksCreate2(
        {
          contact_consent: formData.contact_consent ?? undefined,
          wanted_tool_description: formData.description ?? undefined,
          wanted_tool_name: formData.name ?? undefined,
        },
        params,
      );
    }, 'createNeedToolFeedback');
  }

  async getVersion(): Promise<{ github_sha: string; need_update: boolean }> {
    try {
      const response = await fetch('/version.json');
      const data = await response.json();
      if (!data.github_sha) {
        throw new Error();
      }
      return {
        github_sha: data.github_sha,
        need_update: data.need_update || false,
      };
    } catch (e) {
      const error = new Error('version file not found');
      errorLogger.captureException(error);
      throw error;
    }
  }

  async sendRateFeedback(data: {
    chat_id?: string;
    id?: string;
    rate?: number;
    response?: string;
    slug?: string;
    tool_id?: string;
  }): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1FeedbacksV2RateBarsCreate(data, params);
    }, 'sendRateFeedback');
  }

  async sendOutputFeedback(dataToSend: {
    chat_id?: string;
    comment?: string;
    rate?: number;
    id?: string;
    reasons?: string[];
    response?: string;
    slug?: string;
    tool_id?: string;
  }): Promise<string> {
    return this.makeAuthorizedRequest<string>(async (params) => {
      const { data } = await baseApiClient.api.v1FeedbacksV2OutputsCreate(
        dataToSend,
        params,
      );

      if (data.data.id) {
        return data.data.id;
      }
      throw Error('Invalid response');
    }, 'sendOutputFeedback');
  }

  async sendFeatureFeedback(data: {
    chat_id?: string;
    feature_name?: string;
    id?: string;
    rate?: number;
    response?: string;
    slug?: string;
    tool_id?: string;
  }): Promise<void> {
    return this.makeAuthorizedRequest<void>(async (params) => {
      await baseApiClient.api.v1FeedbacksV2FeaturesCreate(data, params);
    }, 'sendFeatureFeedback');
  }

  async sendExtensionPostFeedback(data: {
    user_id?: string;
    reasons?: string[];
    suggestion?: string;
  }): Promise<void> {
    try {
      await baseApiClient.api.v1ChromeExtensionPostDeleteFeedbackCreate({
        ...(data.suggestion ? { suggestion: data.suggestion } : {}),
        ...(data.reasons ? { reasons: data.reasons } : {}),
        ...(data.user_id ? { user_id: data.user_id } : {}),
      });
    } catch (error) {
      throw await PlatformApi.handleError(error, 'sendExtensionPostFeedback');
    }
  }

  async getCountry(): Promise<string> {
    const provider1 = async (): Promise<string> => {
      const response = await fetch('https://api.db-ip.com/v2/free/self');
      const data = await response.json();
      if (!data.countryCode) {
        throw new Error('getCountry provider1 code not found');
      }
      return data.countryCode;
    };

    const provider2 = async (): Promise<string> => {
      const response = await fetch('https://ipapi.co/json');
      const data = await response.json();
      if (!data.country_code) {
        throw new Error('getCountry provider2 code not found');
      }
      return data.country_code;
    };

    try {
      const countryCode = await Promise.any([provider1(), provider2()]);
      analytics.trackEvent('temp - get country code success');
      return countryCode;
    } catch (e: any) {
      analytics.trackEvent('temp - get country code error');
      errorLogger.captureException(e);
      return 'US';
    }
  }
}

export default new PlatformApi({
  storage: localStorage,
  notAuthorizedCallback: (): void => {
    if (localStorage.getByKey('isDevMode')) {
      console.warn('reload page');
    } else {
      window.location.reload();
    }
  },
});
