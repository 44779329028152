import React, { FC, useEffect } from 'react';

import { useModalContext } from '~/contexts/modal/ModalContext';
import ChooseSubscriptionModal, {
  ReasonType,
} from '~/modules/settings/components/modals/ChooseSubscriptionModal';
import analytics from '~/services/analytics';
import { AnalyticsEventNames } from '~/services/analytics/types';
import DateService from '~/services/Date';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

type Props = {
  children?: React.ReactNode;
};

const config = {
  AMOUNT_OF_TASKS: 6, // tasks user has to complete
  VISITS_BREAKPOINTS: [1, 2, 4, 7, 11], // breakpoints of modal display. Like displaying at 5th, 7th and 12th days
  VISITS_DIFFERENCE_INTERVAL: DateService.differenceInDays, // function to get a period
};

// // TEST CONFIG
// const config = {
//   AMOUNT_OF_TASKS: 3, // tasks user has to complete
//   VISITS_BREAKPOINTS: [1, 2, 4, 7, 11], // breakpoints of modal display. Like displaying at 5th, 7th and 12th days
//   VISITS_DIFFERENCE_INTERVAL: DateService.differenceInMinutes, // function to get an interval between visits period
// };

const getDifferenceInDays = (prevDay): number => {
  const prevDate = new Date(prevDay);
  const currentDate = new Date();
  return config.VISITS_DIFFERENCE_INTERVAL(currentDate, prevDate);
};

const checkIsSubNotActiveModal = (status, interval): boolean => {
  return (
    [SUBSCRIPTION_STATUS.CANCELLED, SUBSCRIPTION_STATUS.REDEMPTION].includes(
      status,
    ) && interval
  );
};

const checkIsNoWordsModal = (words, interval): boolean => {
  return words <= 0 && interval;
};

const SubModalsMiddleware: FC<Props> = ({ children }) => {
  const profile = useAppSelector((state) => state.profile);
  const { handleOpenModal } = useModalContext();
  const { updateUserExtraFields } = useActions();

  const invokeScenario = (reason: ReasonType): void => {
    analytics.trackEvent(
      `subscription - ${reason} screen` as AnalyticsEventNames,
      {
        opening_method: 'trigger',
        sub: profile.user_subscription.subscription_name,
      },
    );

    handleOpenModal({
      maxWidth: '460px',
      onClose: () => {
        analytics.trackEvent(
          `subscription - close ${reason} screen` as AnalyticsEventNames,
          { opening_method: 'trigger' },
        );
      },

      component: ({ onClose }) => (
        <ChooseSubscriptionModal onClose={onClose} type={reason} />
      ),
    });
  };

  const setUserVisitsAmount = (amount): void => {
    updateUserExtraFields({
      notifications: {
        visits: {
          last_visit_date: new Date().toISOString(),
          visits_amount: amount,
        },
      },
    });
  };

  useEffect(() => {
    const {
      user_subscription,
      extra_fields: { notifications },
      words_amount_left,
    } = profile;

    const daysDifference = !notifications.visits
      ? 0
      : getDifferenceInDays(notifications.visits.last_visit_date);

    const isNotActiveSubModal = checkIsSubNotActiveModal(
      user_subscription.status,
      !notifications.visits || daysDifference >= 1,
    );

    if (isNotActiveSubModal) {
      invokeScenario(ReasonType.NO_ACTIVE_SUBSCRIPTION);

      setUserVisitsAmount(
        notifications.visits ? notifications.visits.visits_amount : 1,
      );

      return;
    }

    const isNoWordsModal = checkIsNoWordsModal(
      words_amount_left,
      !notifications.visits || daysDifference >= 1,
    );

    if (isNoWordsModal) {
      invokeScenario(ReasonType.NO_WORDS);
      setUserVisitsAmount(
        notifications.visits ? notifications.visits.visits_amount : 1,
      );
      return;
    }

    if (daysDifference >= 1) {
      setUserVisitsAmount(notifications.visits.visits_amount + 1);
    }
  }, [profile]);

  return <>{children}</>;
};

export default SubModalsMiddleware;
