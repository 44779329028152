// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._edWCjxsLYK6vBMk6GBD{max-width:90%;color:#46474a;font-weight:400 !important}._edWCjxsLYK6vBMk6GBD a{text-decoration:underline}`, "",{"version":3,"sources":["webpack://./src/components/atoms/ModalContentText/styles.module.scss","webpack://./src/styles/_colors.scss"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,aCaW,CDZX,0BAAA,CAEA,wBACE,yBAAA","sourcesContent":["@import \"src/styles/colors\";\n\n.text {\n  max-width: 90%;\n  color: $neutral-20;\n  font-weight: 400 !important;\n\n  a {\n    text-decoration: underline;\n  }\n}","$black: #000000;\r\n$white: #FFFFFF;\r\n\r\n$primary-20: #00164F;\r\n$primary-30: #13307E;\r\n$primary-40: #3B5AAE;\r\n$primary-50: #708DE4;\r\n$primary-80: #DBE1FF;\r\n\r\n$gradient-40: linear-gradient(90deg, #00B39B, #3B71F7);\r\n\r\n$error-20: #410001;\r\n$error-30: #7D0008;\r\n$error-40: #E24D39;\r\n$error-50: #FF8A7C;\r\n$error-80: #FFDAD5;\r\n\r\n$neutral-20: #46474A;\r\n$neutral-30: #76777A;\r\n$neutral-40: #AEAFB1;\r\n$neutral-60: #DBDCDF;\r\n$neutral-80: #EEF0F5;\r\n\r\n$warning-40: #F9E0A2;\r\n$warning-50: #F5D174;\r\n$warning-60: #EFB217;\r\n\r\n$green-30: #006B5D;\r\n$green-50: #00B39B;\r\n$green-70: #80D9CD;\r\n$green-90: #CCF0EB;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `_edWCjxsLYK6vBMk6GBD`
};
export default ___CSS_LOADER_EXPORT___;
