// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pjVJtCWSbL8LhEii6vNq{display:block;width:100%;margin-bottom:24px}.Ibs76q9xASTctzYXFzjF{margin-bottom:24px !important}.AAPiUQZ4fVvW6TjLn649{margin-bottom:32px}.FEpFy9dsI1rr5owa4pvA{font-weight:600 !important}`, "",{"version":3,"sources":["webpack://./src/modules/cancellation/components/modals/DownsellModal/styles.module.scss"],"names":[],"mappings":"AAEA,sBACE,aAAA,CACA,UAAA,CACA,kBAAA,CAGF,sBACE,6BAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,0BAAA","sourcesContent":["@import \"src/styles/mixins\";\n\n.image {\n  display: block;\n  width: 100%;\n  margin-bottom: 24px;\n}\n\n.title {\n  margin-bottom: 24px !important;\n}\n\n.plans {\n  margin-bottom: 32px;\n}\n\n.button {\n  font-weight: 600 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": `pjVJtCWSbL8LhEii6vNq`,
	"title": `Ibs76q9xASTctzYXFzjF`,
	"plans": `AAPiUQZ4fVvW6TjLn649`,
	"button": `FEpFy9dsI1rr5owa4pvA`
};
export default ___CSS_LOADER_EXPORT___;
