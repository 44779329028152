import React from 'react';

import Api from '~/Api';
import { useModalContext } from '~/contexts/modal/ModalContext';
import { useSnackBarContext } from '~/features/snackbar/SnackbarContext';
import useErrors from '~/hooks/useErrors';
import ChooseSubscriptionModal, {
  ReasonType,
} from '~/modules/settings/components/modals/ChooseSubscriptionModal';
import PaymentFixed from '~/modules/settings/components/modals/ChooseSubscriptionModal/views/PaymentFixed';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';
import { timeout } from '~/utils/common';

export const useRestoreSubscription = (): {
  restoreSubscription: () => Promise<void>;
  restoreSubscriptionHard: () => Promise<void>;
} => {
  const { showSnackbar } = useSnackBarContext();

  const { loadAndSetActualProfileData } = useActions();
  const { reportProfileError } = useErrors();
  const { handleOpenModal } = useModalContext();

  const updateProfileWithDelay = async (): Promise<void> => {
    try {
      await timeout(4000);
      await loadAndSetActualProfileData();
    } catch (e: any) {
      reportProfileError(e);
    }
  };

  const restoreSubscription = async (): Promise<void> => {
    try {
      await Api.restoreSubscription();
      showSnackbar('Subscription Restored', 'info');
      analytics.trackEvent('subscription - sub restored');

      updateProfileWithDelay();
    } catch (e: any) {
      throw Error(e.message || 'Error while restoring plan');
    }
  };

  const restoreSubscriptionHard = async (): Promise<void> => {
    try {
      await Api.restoreSubscriptionHard();
      showSnackbar('Payment Issues Fixed!', 'success', 5000);
      analytics.trackEvent('subscription - sub restored');

      updateProfileWithDelay();

      handleOpenModal({
        hideCloseButton: true,
        disableBackdropClick: true,
        maxWidth: '460px',
        component: ({ onClose }) => <PaymentFixed onClose={onClose} />,
      });
    } catch (e: any) {
      showSnackbar('Unable to fix Payment Issue automatically', 'error', 5000);

      handleOpenModal({
        hideCloseButton: true,
        disableBackdropClick: true,
        maxWidth: '460px',
        component: ({ onClose }) => (
          <ChooseSubscriptionModal
            onClose={(): void => {
              onClose();
            }}
            type={ReasonType.REDEMPTION_FAILED}
          />
        ),
      });
    }
  };

  return {
    restoreSubscription,
    restoreSubscriptionHard,
  };
};
