import React, { FC, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import { SupportCases } from '~/components/molecules/SupportModal';
import SupportCaseOption from '~/components/molecules/SupportModal/components/SupportCaseOption';
import { CancellationPath } from '~/modules/cancellation/constants';
import { SCREEN } from '~/modules/cancellation/views/Cancellation';
import analytics from '~/services/analytics';

import commonStyles from '../../styles.module.scss';

type Props = {
  onClose: () => void;
  onNext: (nextCase: SupportCases) => void;
  onBack: () => void;
};

const CancellationCase: FC<Props> = ({ onClose, onNext, onBack }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClickCancel = (): void => {
    analytics.trackEvent('self-support - click need cancel');
    onClose();
    navigate(
      {
        pathname: CancellationPath,
        search: `?screen=${SCREEN.CANCEL_FORM}`,
      },
      {
        state: {
          from: location.pathname,
        },
      },
    );
  };

  const handleClickOther = (): void => {
    analytics.trackEvent('self-support - click other');
    onNext(SupportCases.OTHER);
  };

  useEffect(() => {
    analytics.trackEvent('self-support - sub cancellation view');
  }, []);

  return (
    <div>
      <ModalContentTitle
        text="How can we help you?"
        className={commonStyles.title}
      />

      <div className={commonStyles.options}>
        <SupportCaseOption onClick={handleClickCancel}>
          I want to cancel subscription
        </SupportCaseOption>

        <SupportCaseOption onClick={handleClickOther}>
          I have other questions
        </SupportCaseOption>
      </div>

      <div className={commonStyles.buttons_container}>
        <Button color="primary" variant="contained" onClick={onClose}>
          Done
        </Button>

        <Button color="primary" variant="text" onClick={onBack}>
          Back
        </Button>
      </div>
    </div>
  );
};

export default CancellationCase;
