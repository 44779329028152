import React, { useCallback, useEffect, useState } from 'react';

import Api from '~/Api';
import PaymentForm, {
  paymentFormModalConfig,
} from '~/components/organisms/PaymentForm/views/PaymentForm';
import { useModalContext } from '~/contexts/modal/ModalContext';
import useErrors from '~/hooks/useErrors';
import DownsellModal from '~/modules/cancellation/components/modals/DownsellModal/index';
import analytics from '~/services/analytics';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { ProductType } from '~/types/product';
import { timeout } from '~/utils/common';

type InvokeDownsellParams = {
  downsellPlan: ProductType;
  onSubmitDownsell: () => void;
  onCloseDownsell: () => void;
};

const useDownsell = (): {
  downsellPlans: ProductType[] | null;
  invokeDownsell: (params: InvokeDownsellParams) => void;
} => {
  const [downsellPlans, setDownsellPlans] = useState<ProductType[] | null>(
    null,
  );

  const { handleOpenModal } = useModalContext();
  const { reportUserErrors, reportProfileError } = useErrors();

  const { loadAndSetActualProfileData } = useActions();
  const { user_product, extra_fields } = useAppSelector(
    (state) => state.profile,
  );

  useEffect((): void => {
    (async (): Promise<void> => {
      try {
        const downsellPlans = await Api.getProductDownsellList();

        setDownsellPlans(downsellPlans?.length !== 0 ? downsellPlans : null);
      } catch (e: any) {
        reportUserErrors({
          error: e,
          method: 'getProductDownsellList',
          userMessage:
            'Error retrieving downsell product list. Check back later or',
        });
      }
    })();
  }, []);

  const invokeDownsell = useCallback(
    (params: InvokeDownsellParams): void => {
      handleOpenModal({
        onClose: () => {
          analytics.setUserProperties({
            accepted_downsell_offer: 'declined',
          });

          analytics.trackEvent('subscription - cancel downsell offer', {
            display_downsell: extra_fields.ab_tests.downsell_display,
          });
          params.onCloseDownsell();
        },
        component: () => (
          <DownsellModal
            currentPlan={user_product}
            downsellPlan={params.downsellPlan}
            onSubmit={(): void => {
              analytics.setUserProperties({
                accepted_downsell_offer: 'accepted',
              });

              handleOpenModal({
                onClose: () => {
                  analytics.trackEvent('platform checkout - close');
                  params.onCloseDownsell();
                },
                ...paymentFormModalConfig,
                component: ({ onClose }) => (
                  <PaymentForm
                    product={params.downsellPlan}
                    onSuccessPayment={async (): Promise<void> => {
                      try {
                        await timeout(4000);

                        analytics.setUserProperties({
                          accepted_downsell_offer: 'success',
                        });

                        await params.onSubmitDownsell();
                        await loadAndSetActualProfileData();
                      } catch (e: any) {
                        reportProfileError(e);
                      } finally {
                        onClose();
                      }
                    }}
                  />
                ),
              });
            }}
          />
        ),
      });
    },
    [downsellPlans],
  );

  return { downsellPlans, invokeDownsell };
};

export default useDownsell;
