import React, { FC, useEffect } from 'react';

import SupportModal from '~/components/molecules/SupportModal';
import { useModalContext } from '~/contexts/modal/ModalContext';
import SubmitCancellationModal from '~/modules/cancellation/components/modals/SubmitCancellationModal';
import Control from '~/modules/cancellation/views/Cancellation/screens/Entry/variants/Control';
import analytics from '~/services/analytics';

type Props = {
  onPauseSub: () => void;
  onCancelSub: () => void;
  onRefuse: () => void;
};

const Entry: FC<Props> = ({ onCancelSub, onPauseSub, onRefuse }) => {
  const { handleOpenModal } = useModalContext();

  useEffect(() => {
    analytics.trackEvent('subscription - ac.pathfinder screen');
  }, []);

  const handleSupportClick = (): void => {
    analytics.trackEvent('subscription - ac.click support link');

    handleOpenModal({
      component: ({ onClose }) => <SupportModal onClose={onClose} />,
    });
  };

  const handlePauseSubClick = (): void => {
    analytics.trackEvent('subscription - ac.want pause');
    onPauseSub();
  };

  const handleCancelSubClick = (): void => {
    analytics.trackEvent('subscription - ac.want cancel 2');

    handleOpenModal({
      component: ({ onClose }) => (
        <SubmitCancellationModal
          onRefuse={(): void => {
            onRefuse();
            onClose();
          }}
          onSubmit={(): void => {
            onCancelSub();
            onClose();
          }}
        />
      ),
    });
  };

  return (
    <Control
      onPauseSub={handlePauseSubClick}
      onCancelSub={handleCancelSubClick}
      onSupport={handleSupportClick}
    />
  );
};

export default Entry;
