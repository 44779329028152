import React, { FC, useState } from 'react';

import { Typography } from '@mui/material';

import DropdownCustom from '~/components/molecules/DropdownCustom';
import RadioGroup from '~/components/molecules/RadioGroup';
import ButtonsBlock from '~/modules/cancellation/components/ButtonsBlock';
import {
  CancellationFormDataType,
  FormFields,
  ReasonType,
} from '~/modules/cancellation/types';
import { Typographies } from '~/theme/typography';

import styles from '../styles.module.scss';

type FormStepData = Pick<
  CancellationFormDataType,
  FormFields.REASON | FormFields.SUB_REASON | FormFields.RELATED_TO
>;

type Props = {
  title: string;
  reasons: ReasonType[];
  subReasons: Record<Exclude<ReasonType, 'Other'>, string[]>;
  relations: string[];
  onSubmit: (data: FormStepData) => void;
  onRefuse: () => void;
};

const ReasonStep: FC<Props> = ({
  title,
  reasons,
  subReasons,
  relations,
  onSubmit,
  onRefuse,
}) => {
  const [form, setForm] = useState<FormStepData>({
    [FormFields.REASON]: '',
    [FormFields.SUB_REASON]: '',
    [FormFields.RELATED_TO]: '',
  });

  const handleChange = (key: keyof FormStepData, value: string): void => {
    setForm((prev) => ({
      ...prev,
      [key]: value,
      ...(key === FormFields.REASON ? { [FormFields.SUB_REASON]: '' } : {}),
    }));
  };

  const handleSubmit = (): void => {
    onSubmit(form);
  };

  const isButtonDisabled =
    !form.reason ||
    (form.reason !== 'Other' && !form.sub_reason) ||
    (relations.length > 0 && !form.related_to);

  return (
    <div>
      <div className={styles.form__fieldset}>
        <Typography variant={Typographies.TITLE_LARGE} component="h4">
          {title}
        </Typography>
        <div className={styles.width_limit}>
          <DropdownCustom
            options={reasons.map((opt) => ({ text: opt, value: opt }))}
            selectedOption={
              form.reason
                ? { text: form.reason, value: form.reason }
                : { text: 'Select a reason', value: 'Select a reason' }
            }
            onOptionClick={(opt: { value: string; text: string }): void =>
              handleChange(FormFields.REASON, opt.value)
            }
          />
        </div>

        {subReasons[form.reason] && (
          <RadioGroup
            name="sub_reason"
            value={form.sub_reason}
            onChange={(e): void =>
              handleChange(FormFields.SUB_REASON, e.target.value)
            }
            options={subReasons[form.reason]}
          />
        )}
      </div>

      {(form.sub_reason || form.reason === 'Other') && relations.length > 0 && (
        <div className={styles.form__fieldset}>
          <Typography variant={Typographies.TITLE_MEDIUM} component="p">
            This is related to ....
          </Typography>

          <RadioGroup
            name="related_to"
            value={form.related_to}
            onChange={(e): void =>
              handleChange(FormFields.RELATED_TO, e.target.value)
            }
            options={relations}
          />
        </div>
      )}

      <ButtonsBlock
        buttonSubmitText="Continue"
        buttonRefuseText="Cancel"
        onButtonSubmit={handleSubmit}
        onButtonRefuse={onRefuse}
        isDisabledSubmitButton={isButtonDisabled}
      />
    </div>
  );
};

export default ReasonStep;
