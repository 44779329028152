import React from 'react';

import { Icon } from '@iconify/react';

import PlusImg from '~/assets/images/plus.webp';
import { PRODUCT } from '~/constants/products';

import { ReactComponent as ContentDetector } from './content_detector.svg';
import styles from './styles.module.scss';

import { OrderDescriptionType } from './index';

export const ExtraProductDescriptions: Record<string, OrderDescriptionType> = {
  [PRODUCT.ONETIME_DETECTORS_10_DOLLARS_10K_WORDS]: {
    details_list_ui: [
      <>
        <Icon icon="foundation:check" className={styles.detail__icon} />
        <span>10,000 words</span>
      </>,
      <>
        <Icon icon="foundation:check" className={styles.detail__icon} />
        <span>Extra words is a one-time purchase</span>
      </>,
      <>
        <Icon icon="foundation:check" className={styles.detail__icon} />
        <span>It will not affect the weekly limit</span>
      </>,
    ],
    image_src_ui: PlusImg,
  },
  [PRODUCT.ONETIME_DETECTORS_10_DOLLARS_2K_WORDS]: {
    details_list_ui: [
      <>
        <Icon icon="foundation:check" className={styles.detail__icon} />
        <span>2,000 words</span>
      </>,
      <>
        <Icon icon="foundation:check" className={styles.detail__icon} />
        <span>Extra words is a one-time purchase</span>
      </>,
      <>
        <Icon icon="foundation:check" className={styles.detail__icon} />
        <span>It will not affect the weekly limit</span>
      </>,
    ],
    image_src_ui: PlusImg,
  },
  [PRODUCT.UNBLOCK_DETECTOR_TOOLS_10_DOLLARS]: {
    details_list_ui: [
      <>
        <Icon
          icon="material-symbols:quick-reference-all-outline-rounded"
          className={styles.detail__paraphrase_icon}
        />
        <span>Plagiarism Level Scanner</span>
      </>,
      <>
        <ContentDetector />
        <span>AI Content Detector</span>
      </>,
      <>
        <Icon
          icon="material-symbols:frame-person"
          className={styles.detail__paraphrase_icon}
        />
        <span>AI Text Humanizer</span>
      </>,
    ],
    image_src_ui: PlusImg,
  },
  [PRODUCT.UNBLOCK_DETECTOR_TOOLS_20_DOLLARS]: {
    details_list_ui: [
      <>
        <Icon
          icon="material-symbols:quick-reference-all-outline-rounded"
          className={styles.detail__paraphrase_icon}
        />
        <span>Plagiarism Level Scanner</span>
      </>,
      <>
        <ContentDetector />
        <span>AI Content Detector</span>
      </>,
      <>
        <Icon
          icon="material-symbols:frame-person"
          className={styles.detail__paraphrase_icon}
        />
        <span>AI Text Humanizer</span>
      </>,
    ],
    image_src_ui: PlusImg,
  },
};
