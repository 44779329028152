import React, { FC } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import { Typographies } from '~/theme/typography';

import { ReactComponent as TasksIcon } from './assets/tasks.svg';
import { ReactComponent as TimeIcon } from './assets/time.svg';
import { ReactComponent as WordsIcon } from './assets/words.svg';
import styles from './styles.module.scss';

const icons = {
  time: <TimeIcon />,
  tasks: <TasksIcon />,
  words: <WordsIcon />,
};

export type FomoItemType = {
  id: 'time' | 'tasks' | 'words';
  title: string;
  amount: string;
};

type Props = {
  list: FomoItemType[];
};

const FomoList: FC<Props> = ({ list }) => {
  return (
    <div className={styles.list}>
      {list.map((item) => (
        <div className={clsx(styles.item, styles[item.id])} key={item.id}>
          <div className={styles.item__icon}>{icons[item.id]}</div>

          <Typography
            sx={{
              typography: {
                xs: Typographies.TITLE_SMALL,
                lg: Typographies.TITLE_MEDIUM,
              },
            }}
            component="h4"
            className={styles.item__title}
          >
            {item.title}
          </Typography>

          <Typography
            sx={{
              typography: {
                xs: Typographies.HEADLINE_SMALL,
                lg: Typographies.HEADLINE_MEDIUM,
              },
            }}
            component="p"
            className={styles.item__amount}
          >
            {item.amount}
          </Typography>
        </div>
      ))}
    </div>
  );
};

export default FomoList;
