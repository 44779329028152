import { ToolType } from '~/modules/tools/types';

export type UsageStatisticsType = {
  user_last_tool_name: string;
  user_popular_tool_name: string;
  user_last_tools: ToolType[];
};

export type ReasonType =
  | 'Support Experience'
  | 'Technical Issues'
  | 'Value for Money'
  | 'Objective Achievement'
  | 'Functionality Needs'
  | 'Ease of Use'
  | 'Other';

export enum FormFields {
  REASON = 'reason',
  SUB_REASON = 'sub_reason',
  RELATED_TO = 'related_to',
  FEEDBACK = 'feedback',
  PASSWORD = 'password',
}

export type CancellationFormDataType = {
  [FormFields.REASON]: string;
  [FormFields.SUB_REASON]: string;
  [FormFields.RELATED_TO]: string;
  [FormFields.FEEDBACK]: string;
};
