import {
  GrowthBook as NativeGrowthBook,
  TrackingCallback,
} from '@growthbook/growthbook';

import { AB_TEST_FEATURES, AB_TESTS_KEYS } from '~/services/abTests/features';

class GrowthBook {
  instance: null | NativeGrowthBook<Record<string, any>> = null;

  setUp(clientKey: string, trackingCallbacks: TrackingCallback[]): void {
    if (clientKey) {
      this.instance = new NativeGrowthBook({
        apiHost: 'https://cdn.growthbook.io',
        clientKey,
        enableDevMode: true,
        trackingCallback: (experiment, result): void => {
          trackingCallbacks.forEach(
            (trackingCallback: TrackingCallback): void => {
              trackingCallback(experiment, result);
            },
          );
        },
      });
    }
  }

  async init(attributes: Record<string, any>): Promise<void> {
    if (this.instance) {
      await this.instance.setAttributes(attributes);
      await this.instance.init();
    }
  }

  getAbTestValue(key: AB_TESTS_KEYS): string {
    const defaultValue = AB_TEST_FEATURES[key].defaultVariant;

    if (this.instance) {
      return this.instance.getFeatureValue(key, defaultValue);
    }

    return defaultValue;
  }

  compareAbTests(items: { key: AB_TESTS_KEYS; value: string }[]): boolean {
    return items.every(({ key, value }) => this.getAbTestValue(key) === value);
  }
}

const growthBook = new GrowthBook();

export { growthBook };
