import React, { FC } from 'react';

import { Icon } from '@iconify/react';
import clsx from 'clsx';

import styles from './styles.module.scss';

type Props = {
  steps: number;
  activeStepIdx: number;
};

const CustomizedStepper: FC<Props> = ({ steps, activeStepIdx }) => {
  return (
    <div className={styles.container}>
      {[...Array(steps).keys()].map((step, index) => {
        const isCurrent = index === activeStepIdx;
        const isPassed = index < activeStepIdx;

        return (
          <div
            className={clsx(styles.step, {
              [styles.is_current]: isCurrent,
              [styles.is_passed]: isPassed,
            })}
            key={step}
          >
            <div className={styles.circle}>
              {isPassed && (
                <Icon className={styles.icon} icon="pajamas:check-sm" />
              )}
              {isCurrent && <div className={styles.dot} />}
            </div>
            {index !== steps - 1 && <div className={styles.line} />}
          </div>
        );
      })}
    </div>
  );
};

export default CustomizedStepper;
