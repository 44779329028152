import React, { FC } from 'react';

import { Typography } from '@mui/material';

import Button from '~/components/atoms/buttons/Button';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import ViewAllPlansLink from '~/modules/settings/components/modals/ChooseSubscriptionModal/elements/ViewAllPlansLink';
import ProductCard from '~/modules/settings/components/ProductCard';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';
import { ProductType } from '~/types/product';
import { snakeCaseToCapitalizeCase } from '~/utils/text';

import styles from '../styles.module.scss';

type Props = {
  planDurationDate: string;
  currentProduct: ProductType;
  newProduct: ProductType;
  onSubmit: () => void;
  onViewPlansClick?: () => void;
};

const NoWords: FC<Props> = ({
  planDurationDate,
  currentProduct,
  newProduct,
  onSubmit,
  onViewPlansClick,
}) => {
  const handleSubmit = (): void => {
    analytics.trackEvent('subscription - click no words screen');
    onSubmit();
  };

  return (
    <div>
      <ModalContentTitle
        className={styles.title}
        text={`You've Reached Your Word Limit for this ${snakeCaseToCapitalizeCase(
          currentProduct.regularity,
        )}`}
      />

      <ul className={styles.list}>
        <li className={styles.item}>
          <Typography variant={Typographies.TITLE_MEDIUM} component="p">
            Wait until {planDurationDate} for more words.
          </Typography>
        </li>
        <li className={styles.item}>
          <Typography variant={Typographies.TITLE_MEDIUM} component="p">
            Upgrade your plan for immediate access.
          </Typography>
        </li>
      </ul>

      <Typography
        className={styles.subtitle}
        variant={Typographies.TITLE_MEDIUM}
        component="p"
      >
        Recommended Upgrade:
      </Typography>

      <div className={styles.product__list}>
        {currentProduct.id !== newProduct.id && (
          <div className={styles.product__card}>
            <ProductCard
              orientation="vertical"
              product={currentProduct}
              isDisabled
            />
          </div>
        )}

        <div className={styles.product__card}>
          <ProductCard
            orientation={
              currentProduct.id !== newProduct.id ? 'vertical' : 'horizontal'
            }
            product={newProduct}
            isActive
            isAccent={!!newProduct.discount_percentage_ui}
          />
        </div>
      </div>

      <Button
        variant="contained"
        color="gradient"
        size="large"
        fullWidth
        onClick={handleSubmit}
      >
        Upgrade Plan
      </Button>

      {onViewPlansClick && <ViewAllPlansLink onClick={onViewPlansClick} />}
    </div>
  );
};

export default NoWords;
