import styles from './colors.module.scss';

export const colors: any = {
  black: styles.black,
  white: styles.white,

  primary_20: styles.primary_20,
  primary_30: styles.primary_30,
  primary_40: styles.primary_40,
  primary_50: styles.primary_50,
  primary_80: styles.primary_80,

  gradient_40: styles.gradient_40,

  error_20: styles.error_20,
  error_30: styles.error_30,
  error_40: styles.error_40,
  error_50: styles.error_50,
  error_80: styles.error_80,

  red_40: styles.red_40,

  neutral_20: styles.neutral_20,
  neutral_30: styles.neutral_30,
  neutral_40: styles.neutral_40,
  neutral_60: styles.neutral_60,
  neutral_80: styles.neutral_80,

  warning_40: styles.warning_40,
  warning_50: styles.warning_50,
  warning_60: styles.warning_60,

  green_30: styles.green_30,
  green_50: styles.green_50,
  green_70: styles.green_70,
  green_90: styles.green_90,
};
