import { FomoItemType } from '~/components/molecules/FomoList';

const TIME_SAVED_COEFFICIENT = 2.6;
const TASKS_REMAINING_COEFFICINET = 19;

export const getStatList = (
  isUnlimited: boolean,
  words: number,
): FomoItemType[] => {
  return [
    {
      id: 'words',
      amount: isUnlimited ? 'Unlimited' : `${words.toLocaleString()}`,
      title: 'Words Remaining:',
    },
    {
      id: 'tasks',
      amount: isUnlimited
        ? 'Unlimited'
        : `${Math.trunc(words / TASKS_REMAINING_COEFFICINET).toLocaleString()}`,
      title: 'Tasks Remaining:',
    },
    {
      id: 'time',
      amount: isUnlimited
        ? 'Eternity'
        : `~${Math.trunc(
            (words * TIME_SAVED_COEFFICIENT) / 60,
          ).toLocaleString()}m`,
      title: 'Potential Time Saving:',
    },
  ];
};
