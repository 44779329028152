import React, { FC, useEffect, useState } from 'react';

import { Typography } from '@mui/material';

import Api from '~/Api';
import PasswordField from '~/components/atoms/inputs/PasswordField';
import Loader from '~/components/atoms/Loader';
import useErrors from '~/hooks/useErrors';
import ButtonsBlock from '~/modules/cancellation/components/ButtonsBlock';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';

import styles from '../styles.module.scss';

type Props = {
  buttonSubmitText: string;
  buttonRefuseText: string;
  onSubmit: () => void;
  onRefuse: () => void;
};

const DEFAULT_PASSWORD_HELPER_TEXT =
  'This is required for account owner verification and prevention of unauthorized account deletion.';

const PasswordStep: FC<Props> = ({
  buttonSubmitText,
  buttonRefuseText,
  onSubmit,
  onRefuse,
}) => {
  const [password, setPassword] = useState('');
  const [passwordErrorHelperText, setPasswordErrorHelperText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { reportUserErrors } = useErrors();

  const handleChange = (e): void => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true);
      await Api.checkUserPassword(password);
    } catch (e: any) {
      setPasswordErrorHelperText(e.message || 'Validate password problem :(');
      analytics.trackEvent('subscription - ac.password confirmation error');
      reportUserErrors({
        error: e,
        method: 'checkUserPassword',
        userMessage:
          'Password verification failed. Please re-enter your password or',
      });
      return;
    } finally {
      setIsLoading(false);
    }

    try {
      setIsLoading(true);
      await onSubmit();
    } catch (e: any) {
      console.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setPasswordErrorHelperText('');
  }, [password]);

  return (
    <div>
      {isLoading && <Loader />}

      <div className={styles.form__fieldset}>
        <Typography
          variant={Typographies.TITLE_LARGE}
          component="h4"
          className={styles.password__title}
        >
          Password reconfirmation
        </Typography>

        <PasswordField
          className={styles.password__input}
          value={password}
          error={Boolean(passwordErrorHelperText)}
          onChange={handleChange}
          helperText={passwordErrorHelperText || DEFAULT_PASSWORD_HELPER_TEXT}
        />
      </div>

      <ButtonsBlock
        buttonSubmitText={buttonSubmitText}
        buttonRefuseText={buttonRefuseText}
        onButtonSubmit={handleSubmit}
        onButtonRefuse={onRefuse}
        isDisabledSubmitButton={!password || isLoading}
      />
    </div>
  );
};

export default PasswordStep;
