import { ONBOARDING_SAVE_KEYS } from '~/modules/onboarding-new/constants';
import {
  ProfileAction,
  ProfileActionTypes,
  ProfileState,
} from '~/store/types/profile';
import { ProductType } from '~/types/product';
import { SubscriptionType } from '~/types/subscription';

const initialState: ProfileState = {
  id: '',
  isLoggedIn: false,
  email: '',
  name: '',
  avatar: '',
  words_amount_left: 0,
  response_count_total: 0,
  is_onboarded: true,
  is_chrome_extension_installed: false,
  created_at: '',
  updated_at: '',
  extra_fields: {
    notifications: {},
    ab_tests: {},
    highlights: {},
    test_form: {},
    sign_up_at: null,
    cancel_sub_reasons: {},
    rewards: {},
    feedbackV2: {},
    pwa_modal: {
      last_showed_at: null,
      is_showed_first_generation: false,
      is_showed_fifth_session: false,
    },
  },
  user_product: {} as ProductType,
  user_subscription: {} as SubscriptionType,
  user_onboarding: {} as Record<ONBOARDING_SAVE_KEYS, string>,
  backend_ab_tests: {},
  user_features: {
    image_generation_tool_enabled: false,
  },
};

export const profileReducer = (
  state = initialState,
  action: ProfileAction,
): ProfileState => {
  switch (action.type) {
    case ProfileActionTypes.UPDATE_PROFILE:
      return { ...state, ...action.payload };
    case ProfileActionTypes.SIGN_IN:
      return { ...state, isLoggedIn: true };
    case ProfileActionTypes.SIGN_OUT:
      return { ...state, isLoggedIn: false };
    case ProfileActionTypes.INCREASE_RESPONSE_COUNT:
      return { ...state, response_count_total: state.response_count_total + 1 };
    default:
      return state;
  }
};
