import React, { FC, useEffect } from 'react';

import { Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import DoneImg from '~/assets/images/done.webp';
import DonePreviewImg from '~/assets/images/done_preview.png';
import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import { SUPPORT_EMAIL } from '~/constants';
import { useIsViewport, Viewports } from '~/hooks/useIsViewport';
import { SubscriptionPath } from '~/modules/settings/constants';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

const Success: FC = () => {
  const isDesktop = useIsViewport(Viewports.EXTRA_SMALL_DESKTOP);
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleGoToSubscriptionPage = (): void => {
    navigate(SubscriptionPath);
  };

  useEffect(() => {
    if (!state) {
      handleGoToSubscriptionPage();
      return;
    }

    analytics.trackEvent(
      state?.flow === 'cancel'
        ? 'subscription - verify cancel sub'
        : 'subscription - ac.verify pause',
      {
        ...(state?.period
          ? {
              option:
                state?.period.unit === 'week'
                  ? state?.period.value / 4 // since we changed visual display from weeks to months
                  : state?.period.value,
            }
          : {}),
      },
    );
  }, [state]);

  const handleSupportClick = (): void => {
    analytics.trackEvent('subscription - ac.click support link');
  };

  return (
    <>
      {state && (
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.image}>
              <Image src={DoneImg} preview={DonePreviewImg} />
            </div>

            <Typography
              variant={Typographies.HEADLINE_MEDIUM}
              component="h4"
              className={styles.title}
            >
              You have successfully{' '}
              {state.flow === 'cancel' ? 'cancelled' : 'paused'} your
              subscription, you will not be charged{' '}
              {state.flow === 'pause' && state.period
                ? `for the next ${state?.period.text}`
                : 'again'}
              .
            </Typography>

            <Typography
              variant={Typographies.TITLE_MEDIUM}
              component="p"
              className={styles.text}
            >
              Did we do something wrong? <br /> Please{' '}
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                onClick={handleSupportClick}
                target="_blank"
                rel="noreferrer nooperner"
                className={styles.link}
              >
                let us know
              </a>
              . Thank you!
            </Typography>
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={handleGoToSubscriptionPage}
            fullWidth={!isDesktop}
            sx={{ '&&': { marginTop: '40px' } }}
          >
            Go to Settings
          </Button>
        </div>
      )}
    </>
  );
};

export default Success;
