import React, { FC, useState } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import { StyledTextField } from '~/components/atoms/inputs/TextField';
import ButtonsBlock from '~/modules/cancellation/components/ButtonsBlock';
import {
  CancellationFormDataType,
  FormFields,
} from '~/modules/cancellation/types';
import { Typographies } from '~/theme/typography';

import styles from '../styles.module.scss';

type Props = {
  title: string;
  onSubmit: (data: Pick<CancellationFormDataType, FormFields.FEEDBACK>) => void;
  onRefuse: () => void;
};

const FeedbackStep: FC<Props> = ({ title, onSubmit, onRefuse }) => {
  const [feedback, setFeedback] = useState('');

  const handleChange = (e): void => {
    setFeedback(e.target.value);
  };

  const handleSubmit = (): void => {
    onSubmit({ feedback });
  };

  return (
    <div>
      <div className={styles.form__fieldset}>
        <Typography
          variant={Typographies.TITLE_MEDIUM}
          className={styles.width_limit}
          component="h4"
        >
          {title}
        </Typography>

        <StyledTextField
          value={feedback}
          onChange={handleChange}
          className={clsx(styles.textarea, styles.width_limit)}
          multiline
          fullWidth
          rows={8}
          autoComplete="off"
        />
      </div>

      <ButtonsBlock
        buttonSubmitText="Continue"
        buttonRefuseText="Cancel"
        onButtonSubmit={handleSubmit}
        onButtonRefuse={onRefuse}
      />
    </div>
  );
};

export default FeedbackStep;
