import React, { FC, useEffect } from 'react';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import ModalContentText from '~/components/atoms/ModalContentText';
import ModalContentTitle from '~/components/atoms/ModalContentTitle';
import ProductCard from '~/modules/settings/components/ProductCard';
import analytics from '~/services/analytics';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { ProductType } from '~/types/product';

import GiftImg from './assets/gift.webp';
import GiftPreviewImg from './assets/gift_preview.png';
import styles from './styles.module.scss';

type Props = {
  currentPlan: ProductType;
  downsellPlan: ProductType;
  onSubmit: () => void;
};

const DownsellModal: FC<Props> = ({ onSubmit, downsellPlan }) => {
  const { extra_fields } = useAppSelector((state) => state.profile);

  useEffect(() => {
    analytics.trackEvent('subscription - show downsell pop', {
      display_downsell: extra_fields.ab_tests.downsell_display,
    });
  }, []);

  const handleSubmitClick = (): void => {
    analytics.trackEvent('subscription - confirm downsell offer', {
      product_id: downsellPlan.id,
      product_name: downsellPlan.name,
      display_downsell: extra_fields.ab_tests.downsell_display,
    });
    onSubmit();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.image}>
        <Image src={GiftImg} preview={GiftPreviewImg} />
      </div>

      <ModalContentTitle
        text={`Special ${
          downsellPlan.discount_percentage_ui
            ? `${downsellPlan.discount_percentage_ui}%`
            : ''
        } Discount Just for You!`}
        className={styles.title}
      />

      <ModalContentText sx={{ mb: '24px', maxWidth: 'unset' }}>
        Make your journey with us more affordable and productive.
      </ModalContentText>

      <div className={styles.plans}>
        <ProductCard
          isSelectable
          product={downsellPlan}
          isActive
          isAccent={!!downsellPlan.discount_percentage_ui}
        />
      </div>

      <Button
        className={styles.button}
        variant="contained"
        color="gradient"
        size="large"
        fullWidth
        onClick={handleSubmitClick}
      >
        Claim Discount
      </Button>
    </div>
  );
};

export default DownsellModal;
