import React, { FC, useEffect } from 'react';

import { isEqual } from 'lodash';

import { useModalContext } from '~/contexts/modal/ModalContext';
import AddToHomeModalV1 from '~/features/pwa/versions/V1/AddToHomeModal';
import analytics from '~/services/analytics';
import DateService from '~/services/Date';
import sessionCounter from '~/services/sessionCounter';
import { useActions } from '~/store/hooks/useActions';
import { useAppSelector } from '~/store/hooks/useAppSelector';
import { PWAModalExtraFieldsType } from '~/types/profile';
import { getDeviceName } from '~/utils/userAgentInfo';

type Props = {
  children?: React.ReactNode;
};

export const SESSION_TO_TRIGGER_COUNT = 'session_to_trigger_count';

const PWAModalMiddleware: FC<Props> = ({ children }) => {
  const currentSessionCount = sessionCounter.getCurrentCount();
  const profile = useAppSelector(
    (state) => state.profile,
    (state1, state2) => !isEqual(state1.extra_fields, state2.extra_fields),
  );
  const { updateUserExtraFields } = useActions();
  const { handleOpenModal } = useModalContext();

  const handleShowAddToHomeModal = (
    extra: Partial<PWAModalExtraFieldsType>,
    analytic_reason_property_value: string,
  ): void => {
    const deviceName = getDeviceName();

    if (!deviceName) {
      return;
    }

    const eventProperties = {
      reason: analytic_reason_property_value,
      version: 'v1',
    };

    handleOpenModal({
      onClose: () =>
        analytics.trackEvent('retention - close pwa modal', eventProperties),
      maxWidth: '460px',
      hideCloseButton: true,
      disableBackdropClick: true,
      paddingSize: '24px',
      position: { horizontal: 'center', vertical: 'center' },
      component: ({ onClose }) => (
        <AddToHomeModalV1
          device={deviceName}
          onSubmit={(): void => {
            onClose();
            analytics.trackEvent(
              'retention - click pwa modal',
              eventProperties,
            );
          }}
        />
      ),
    });

    analytics.trackEvent('retention - pwa modal', eventProperties);

    updateUserExtraFields({
      pwa_modal: {
        ...profile.extra_fields.pwa_modal,
        last_showed_at: new Date().toISOString(),
        ...extra,
      },
    });
  };

  const checkAndShowModal = (): void => {
    if (
      profile.extra_fields.pwa_modal.last_showed_at &&
      Math.abs(
        DateService.differenceInDays(
          new Date(profile.extra_fields.pwa_modal.last_showed_at),
          new Date(),
        ),
      ) < 1
    ) {
      return;
    }

    const session_to_trigger_count = localStorage.getItem(
      SESSION_TO_TRIGGER_COUNT,
    );

    if (
      !profile.extra_fields.pwa_modal.is_showed_first_generation &&
      session_to_trigger_count &&
      currentSessionCount > Number(session_to_trigger_count)
    ) {
      handleShowAddToHomeModal(
        {
          is_showed_first_generation: true,
        },
        'next_visit_after_1st_gen',
      );
      return;
    }

    if (
      !profile.extra_fields.pwa_modal.is_showed_fifth_session &&
      currentSessionCount >= 5
    ) {
      handleShowAddToHomeModal(
        {
          is_showed_fifth_session: true,
        },
        '5th_visit',
      );
      return;
    }
  };

  useEffect(() => {
    checkAndShowModal();
  }, [profile]);

  return <>{children}</>;
};

export default PWAModalMiddleware;
