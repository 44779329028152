import { merge } from 'lodash';
import { Dispatch } from 'redux';

import API from '~/Api';
import analytics from '~/services/analytics';
import errorLogger from '~/services/ErrorLogger';
import { firebase } from '~/services/firebase/Firebase';
import { ProfileAction, ProfileActionTypes } from '~/store/types/profile';
import { ExtraFields } from '~/types/profile';
import { SUBSCRIPTION_STATUS } from '~/types/subscription';

const loadAndDispatchActualCustomerData = async (
  dispatch: Dispatch<ProfileAction>,
  getState,
  from?: 'signUp',
): Promise<void> => {
  const profile = await API.getProfile();

  analytics.checkAndUpdateUserId(profile.id);
  errorLogger.setUser(profile.id);

  analytics.addDefaultEventParams({
    email: profile.email,
    ...profile.backend_ab_tests,
    ...(process.env.GITHUB_SHA ? { github_sha: process.env.GITHUB_SHA } : {}),
  });
  analytics.setUserProperties({
    email: profile.email,
    ...profile.backend_ab_tests,
  });

  dispatch({
    type: ProfileActionTypes.UPDATE_PROFILE,
    payload: {
      ...profile,
    },
  });

  const newAbTests = await firebase.getNewABTests(
    profile.extra_fields?.ab_tests,
  );

  const extra_fields: Record<string, any> = {};

  if (from === 'signUp') {
    extra_fields.sign_up_at = new Date().toISOString();
  }

  if (Object.keys(newAbTests).length) {
    analytics.setUserProperties(newAbTests);
    extra_fields.ab_tests = newAbTests;
  }

  if (Object.keys(extra_fields).length) {
    await updateUserExtraFields(extra_fields)(dispatch, getState);
  }
};

export const loadAndDispatchActualSubscriptionData = () => {
  return async (dispatch: Dispatch<ProfileAction>, getState): Promise<void> => {
    try {
      const profile = getState().profile;
      const user_subscription = await API.getSubscription();

      dispatch({
        type: ProfileActionTypes.UPDATE_PROFILE,
        payload: {
          ...profile,
          user_subscription,
        },
      });
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
};

export const updateUserExtraFields = (newExtraFields: Partial<ExtraFields>) => {
  return async (dispatch: Dispatch<ProfileAction>, getState): Promise<void> => {
    try {
      const profile = getState().profile;
      const mergedExtraFields = merge(profile.extra_fields, newExtraFields);

      dispatch({
        type: ProfileActionTypes.UPDATE_PROFILE,
        payload: {
          ...profile,
          extra_fields: mergedExtraFields,
        },
      });

      await API.updateProfile({ extra_fields: mergedExtraFields });
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
};

export const checkIsUserLoggedIn = () => {
  return async (dispatch: Dispatch<ProfileAction>, getState): Promise<void> => {
    try {
      await loadAndDispatchActualCustomerData(dispatch, getState);

      dispatch({
        type: ProfileActionTypes.SIGN_IN,
      });
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
};

export const signIn = (
  email: string,
  password: string,
  afterSignInCallback?: () => Promise<void>,
) => {
  return async (dispatch: Dispatch<ProfileAction>, getState): Promise<void> => {
    try {
      await API.signIn(email, password);
      await afterSignInCallback?.();
      await loadAndDispatchActualCustomerData(dispatch, getState);

      dispatch({
        type: ProfileActionTypes.SIGN_IN,
      });
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
};

export const signUp = (data: {
  email: string;
  name: string;
  password: string;
  abTests?: Record<string, any>;
}) => {
  return async (dispatch: Dispatch<ProfileAction>, getState): Promise<void> => {
    try {
      await API.signUp({
        email: data.email,
        name: data.name,
        password: data.password,
        ...(data.abTests
          ? {
              extra_fields: {
                ab_tests: data.abTests,
              },
            }
          : {}),
      });

      await loadAndDispatchActualCustomerData(dispatch, getState, 'signUp');

      dispatch({
        type: ProfileActionTypes.SIGN_IN,
      });
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
};

export const signOut = () => {
  return async (dispatch: Dispatch<ProfileAction>): Promise<void> => {
    analytics.trackEvent('general - log out');
    API.signOut();
    dispatch({
      type: ProfileActionTypes.SIGN_OUT,
    });
  };
};

export const loadAndSetActualProfileData = () => {
  return async (dispatch: Dispatch<ProfileAction>, getState): Promise<void> => {
    try {
      await loadAndDispatchActualCustomerData(dispatch, getState);

      dispatch({
        type: ProfileActionTypes.SIGN_IN,
      });
    } catch (e: any) {
      console.error(e);
      throw e;
    }
  };
};

export const updateProfileSubscriptionStatus = (
  newStatus: SUBSCRIPTION_STATUS,
) => {
  return async (dispatch: Dispatch<ProfileAction>, getState): Promise<void> => {
    const profile = getState().profile;

    dispatch({
      type: ProfileActionTypes.UPDATE_PROFILE,
      payload: {
        ...profile,
        user_subscription: {
          ...profile.user_subscription,
          status: newStatus,
        },
      },
    });
  };
};

export const increaseResponseCountTotal = () => {
  return async (dispatch: Dispatch<ProfileAction>): Promise<void> => {
    dispatch({
      type: ProfileActionTypes.INCREASE_RESPONSE_COUNT,
    });
  };
};
