import React, { FC } from 'react';

import { Typography } from '@mui/material';
import clsx from 'clsx';

import Button from '~/components/atoms/buttons/Button';
import Image from '~/components/atoms/Image';
import { ReactComponent as UploadAndroidIcon } from '~/features/pwa/assets/android_nav.svg';
import { ReactComponent as UploadDesktopChromeIcon } from '~/features/pwa/assets/desktop_chrome_nav.svg';
import { ReactComponent as UploadSafariIcon } from '~/features/pwa/assets/ios_nav.svg';
import { ReactComponent as UploadSafariIOSIcon } from '~/features/pwa/assets/ios_safari_nav.svg';
import { Typographies } from '~/theme/typography';
import { Device, PickedV1Devices } from '~/utils/userAgentInfo';

import styles from './styles.module.scss';

type Props = {
  device: Device;
  onSubmit: () => void;
};

type PwaScreenConfigType = Record<PickedV1Devices, JSX.Element>;

const CONFIG: PwaScreenConfigType = {
  [Device.Ios_Safari]: (
    <>
      <Typography
        typography={Typographies.HEADLINE_SMALL}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        Add Justdone to Your Home Screen
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1. Tap “ <UploadSafariIcon style={{ marginBottom: '-3px' }} /> ” at the
        bottom.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. Tap “ Add to Home Screen{' '}
        <UploadSafariIOSIcon style={{ marginBottom: '-3px' }} /> ”.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        Or add the page to your browser favorites for quick access.
      </Typography>
    </>
  ),
  [Device.Android_Chrome]: (
    <>
      <Typography
        typography={Typographies.HEADLINE_SMALL}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        Add Justdone to Your Home Screen
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1. Tap “ <UploadAndroidIcon style={{ marginBottom: '-3px' }} /> ” in
        upper right-hand corner.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. Tap “Install app”.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        Or add the page to your browser favorites for quick access.
      </Typography>
    </>
  ),
  [Device.Mac_Chrome]: (
    <>
      <div className={styles.image}>
        <Image
          src="/icons/chrome-address-bar-small.png"
          preview="/icons/chrome-address-bar-small-preview.png"
        />
      </div>
      <Typography
        typography={Typographies.TITLE_LARGE}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        Install Justdone application
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1. Click “{' '}
        <UploadDesktopChromeIcon
          style={{
            height: '22px',
            marginTop: '-3px',
            marginBottom: '-3px',
          }}
        />{' '}
        ” at the top.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. Click “Install”.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        Or add the page to your browser <br /> favorites for quick access.
      </Typography>
    </>
  ),
  [Device.Windows_Chrome]: (
    <>
      <div className={styles.image}>
        <Image
          src="/icons/chrome-address-bar-small.png"
          preview="/icons/chrome-address-bar-small-preview.png"
        />
      </div>
      <Typography
        typography={Typographies.TITLE_LARGE}
        component="h4"
        className={clsx(styles.headline, styles.mb_16)}
      >
        Install Justdone application
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={styles.list_item}
      >
        1. Click “{' '}
        <UploadDesktopChromeIcon
          style={{
            height: '22px',
            marginTop: '-3px',
            marginBottom: '-3px',
          }}
        />{' '}
        ” at the top.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.mb_16)}
      >
        2. Click “Install”.
      </Typography>
      <Typography
        typography={Typographies.TITLE_MEDIUM}
        component="div"
        className={clsx(styles.list_item, styles.extra_info_text)}
      >
        Or add the page to your browser <br /> favorites for quick access.
      </Typography>
    </>
  ),
};

const AddToHomeModalV1: FC<Props> = ({ device, onSubmit }) => {
  return (
    <>
      <div className={styles.container}>{CONFIG[device]}</div>
      <Button
        variant="contained"
        onClick={(): void => {
          onSubmit();
        }}
      >
        Got it
      </Button>
    </>
  );
};

export default AddToHomeModalV1;
