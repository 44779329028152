import React, { ReactElement } from 'react';

import { Icon as SidebarIcon } from '@iconify/react';
import { flowRight } from 'lodash';

import router, { RouteItemType } from '~/core/router';
import { sidebar } from '~/core/sidebar';
import {
  withHomeScreenRetentionLayout,
  withMainLayout,
  withOnboardingLayout,
  withPWAModalLayout,
  withSimpleHeaderLogoLayout,
  withSubModalsLayout,
} from '~/decorators/withLayout';
import lazyComponentLoader from '~/helpers/lazyComponentLoader';
import { checkIsDesktopChrome } from '~/helpers/platform';
import {
  ChromeExtensionOnboardingPath,
  ChromeExtensionPath,
  ChromeExtensionPostDeletePath,
  ChromeExtensionSidebarKey,
  ChromeExtensionTitle,
} from '~/modules/chrome-extension/constants';
import { checkIsChromeBrowser } from '~/modules/chrome-extension/helpers';

const Onboarding = lazyComponentLoader(() => import('./views/Onboarding'));

const Extension = lazyComponentLoader(() => import('./views/Extension'));

const ExtensionPostDelete = lazyComponentLoader(
  () => import('./views/PostDelete'),
);

const ChromeExtensionOnboardingRoute: RouteItemType = {
  Component: flowRight(withSimpleHeaderLogoLayout)(Onboarding),
  path: ChromeExtensionOnboardingPath,
  title: ChromeExtensionTitle,
};

const ExtensionRoute: RouteItemType = {
  Component: flowRight(
    withPWAModalLayout,
    withSubModalsLayout,
    withOnboardingLayout,
    withHomeScreenRetentionLayout,
    withMainLayout,
  )(Extension),
  checkAvailability: checkIsDesktopChrome,
  path: ChromeExtensionPath,
  title: ChromeExtensionTitle,
};

const ExtensionPostDeleteRoute: RouteItemType = {
  Component: ExtensionPostDelete,
  path: ChromeExtensionPostDeletePath,
  title: ChromeExtensionTitle,
  checkAvailability: checkIsChromeBrowser,
};

router.addPrivateRoutes([ChromeExtensionOnboardingRoute, ExtensionRoute]);

router.addUnAuthorizedRoutes([ExtensionPostDeleteRoute]);

sidebar.addItems({
  a: [
    {
      order: 5,
      key: ChromeExtensionSidebarKey,
      path: ExtensionRoute.path,
      title: ExtensionRoute.title,
      checkAvailability: ExtensionRoute.checkAvailability,
      Icon: (): ReactElement => {
        return <SidebarIcon icon="logos:chrome" />;
      },
    },
  ],
});
