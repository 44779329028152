import { ReasonType } from '../../types';

type FormOptionsType = {
  reasons: ReasonType[];
  sub_reasons: Record<Exclude<ReasonType, 'Other'>, string[]>;
  relations: string[];
  feedbacks: Record<Exclude<ReasonType, 'Other'>, string>;
};

export const formOptions: FormOptionsType = {
  reasons: [
    'Support Experience',
    'Technical Issues',
    'Value for Money',
    'Objective Achievement',
    'Functionality Needs',
    'Ease of Use',
    'Other',
  ],
  sub_reasons: {
    'Support Experience': [
      'Long Wait',
      'Unclear Answers',
      'No Resolution',
      'Unclear Contact Channel',
    ],
    'Technical Issues': [
      'Frequent Errors',
      'Slow Performance',
      'Unexpected Downtime',
    ],
    'Value for Money': [
      'Over Budget',
      'Found Cheaper Alternative',
      'Not Using Enough',
      "Didn't See the Value",
    ],
    'Objective Achievement': [
      'Goal Not Met',
      'Changed Objectives',
      'Needed More Guidance',
      'No Problem Just Leaving',
    ],
    'Functionality Needs': [
      'Missing Feature',
      'Needed Customization',
      'Sought Advanced Tools',
      'Sufficient but Want a Change',
    ],
    'Ease of Use': [
      'Hard to Navigate',
      'Unappealing Design',
      'Inaccessible Features',
      'Prefer a Different Layout',
    ],
  },
  relations: [],
  feedbacks: {
    'Support Experience':
      'Tell us where our support could do better: Was it the wait, the clarity, the outcome?',
    'Technical Issues':
      'What tech hiccups did you face: Errors, speed or downtime?',
    'Value for Money':
      'Help us understand the value issue: Was it budget, alternatives, usage, or perceived value?',
    'Objective Achievement':
      'Did we help achieve your goal? Was it the goal, a change in plans, guidance, or something else?',
    'Functionality Needs':
      'Which feature fell short? Was something missing, not customizable, or not advanced enough?',
    'Ease of Use':
      'Was it easy to get around? Tell us about navigation, design, accessibility, or layout preferences.',
  },
};
