import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Loader from '~/components/atoms/Loader';
import FomoList from '~/components/molecules/FomoList';
import ButtonsBlock from '~/modules/cancellation/components/ButtonsBlock';
import { CancellationPath } from '~/modules/cancellation/constants';
import { SCREEN } from '~/modules/cancellation/views/Cancellation';
import analytics from '~/services/analytics';
import { Typographies } from '~/theme/typography';
import { getStatList } from '~/utils/statistics';

import styles from './styles.module.scss';

type Props = {
  onSubmit: () => void;
  onRefuse: () => void;
  isUnlimitedSub: boolean;
  wordsAmountLeft: number;
};

const CancelLoses: FC<Props> = ({
  onSubmit,
  onRefuse,
  isUnlimitedSub,
  wordsAmountLeft,
}) => {
  const navigate = useNavigate();

  const handleCancelSubClick = (): void => {
    analytics.trackEvent('subscription - ac.want cancel 4');
    onSubmit();
  };

  const handleGoToPauseScreen = (): void => {
    navigate(
      { pathname: CancellationPath, search: `?screen=${SCREEN.PAUSE_PERIOD}` },
      { replace: true },
    );
  };

  const statList = getStatList(isUnlimitedSub, wordsAmountLeft);

  const loses = [
    <>
      Your account will be canceled immediately and you will not be charged for
      the next billing cycle.
    </>,
    <>
      If you wish to reactivate your account in the future, you can only do so
      by restoring and at the actual subscription price at the time of the
      operation.
    </>,
    <>
      If you want to use product later, don’t pay and freeze your current prices
      - you can{' '}
      <span onClick={handleGoToPauseScreen} className={styles.link}>
        pause subscription
      </span>{' '}
      instead.
    </>,
  ];

  return (
    <>
      {statList ? (
        <div>
          <div className={styles.content}>
            <Typography
              variant={Typographies.HEADLINE_SMALL}
              component="h4"
              className={styles.content__title}
            >
              Are you sure you want to cancel?
            </Typography>

            <div className={styles.content__flex}>
              <div className={styles.content__fomo}>
                <Typography
                  variant={Typographies.TITLE_MEDIUM}
                  component="h4"
                  className={styles.content__subtitle}
                >
                  Canceling your subscription means losing access to these
                  benefits.
                </Typography>

                <FomoList list={statList} />
              </div>

              <ul className={styles.content__list}>
                {loses.map((text, idx) => (
                  <li className={styles.content__item} key={idx}>
                    <Typography variant={Typographies.BODY_LARGE} component="p">
                      {text}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <ButtonsBlock
            buttonSubmitText="Continue"
            buttonRefuseText="Cancel"
            onButtonSubmit={handleCancelSubClick}
            onButtonRefuse={onRefuse}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CancelLoses;
