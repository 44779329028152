export enum AB_TESTS_KEYS {
  IS_EXTENSION_BUTTON_FREE_PREFIX_SHOWN = 'extension-button-free-prefix-shown',
  SUBSCRIPTION_PAGE_LAYOUT = 'sub-page-layout',
}

export const AB_TEST_FEATURES: Record<
  AB_TESTS_KEYS,
  {
    key: AB_TESTS_KEYS;
    variants: {
      control: string;
      variant_1: string;
      [key: string]: string;
    };
    defaultVariant: string;
  }
> = {
  [AB_TESTS_KEYS.IS_EXTENSION_BUTTON_FREE_PREFIX_SHOWN]: {
    key: AB_TESTS_KEYS.IS_EXTENSION_BUTTON_FREE_PREFIX_SHOWN,
    variants: {
      control: 'control',
      variant_1: 'variant_1',
    },
    defaultVariant: 'control',
  },
  [AB_TESTS_KEYS.SUBSCRIPTION_PAGE_LAYOUT]: {
    key: AB_TESTS_KEYS.SUBSCRIPTION_PAGE_LAYOUT,
    variants: {
      control: 'default',
      variant_1: 'informed',
    },
    defaultVariant: 'default',
  },
};
