import React, { FC, ReactElement } from 'react';

import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';

import { ExtraProductDescriptions } from '~/components/organisms/PaymentForm/components/OrderDescription/constants';
import { Typographies } from '~/theme/typography';

import styles from './styles.module.scss';

export type OrderDescriptionType = {
  details_list_ui: Array<string | ReactElement>;
  image_src_ui: string;
};

export type Props = {
  is_popular_ui: boolean;
  product_id: string;
  product_name: string;
};

const defaultDetailsUi = [
  <>
    <Icon icon="foundation:check" className={styles.detail__icon} />
    <span>Unlimited Words for 120+ Advanced AI Tools</span>
  </>,
  <>
    <Icon icon="foundation:check" className={styles.detail__icon} />
    <span>2000+ word long Article Generation Tool</span>
  </>,
  <>
    <Icon icon="foundation:check" className={styles.detail__icon} />
    <span>Plagiarism Checker and AI Detector</span>
  </>,
  <>
    <Icon icon="foundation:check" className={styles.detail__icon} />
    <span>Ultimate Chrome Extension</span>
  </>,
];

const OrderDescription: FC<Props> = ({
  is_popular_ui,
  product_name,
  product_id,
}) => {
  const extraProductDescription = ExtraProductDescriptions[product_id];

  return (
    <div className={styles.wrapper}>
      {is_popular_ui && (
        <Typography
          className={styles.popular}
          variant={Typographies.BODY_SMALL}
          component="p"
        >
          Most Popular
        </Typography>
      )}

      {extraProductDescription?.image_src_ui && (
        <img
          src={extraProductDescription.image_src_ui}
          alt=""
          className={styles.image}
        />
      )}

      <Typography
        className={styles.title}
        variant={Typographies.TITLE_MEDIUM}
        component="h3"
      >
        {product_name}
      </Typography>

      <Typography
        className={styles.details_title}
        variant={Typographies.LABEL_LARGE}
        component="h3"
      >
        Your order details:
      </Typography>

      <div>
        {(extraProductDescription?.details_list_ui || defaultDetailsUi).map(
          (detail, idx) => (
            <Typography
              key={idx}
              className={styles.detail}
              variant={Typographies.BODY_MEDIUM}
              component="h3"
            >
              {detail}
            </Typography>
          ),
        )}
      </div>
    </div>
  );
};

export default OrderDescription;
