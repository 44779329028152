class ChromeExtension {
  sendAuthTokens(accessToken: string, refreshToken: string): void {
    const signInEvent = new CustomEvent('signIn', {
      detail: {
        access_token: accessToken,
        refresh_token: refreshToken,
      },
    });

    document.dispatchEvent(signInEvent);
  }

  // TODO implement check installed extension method
}

export default new ChromeExtension();
